const API_URL = 'https://drprashantdwivedi.com/csadmin/api/'
// const API_URL = 'https://www.codexotech.in/projects/drprashantdwivedi/csadmin/api/'


const BASE_URL =   ""    
const FRONT_URL =   "https://drprashantdwivedi.com/"    
const DEFAULT_IMAGE="/images/logo.png"
const META_DEFAULT_IMAGE="https://drprashantdwivedi.com/images/logo.png"

const TESTIMONIAL_IMAGE="/images/testimonial.png"

export default { API_URL,BASE_URL,FRONT_URL,DEFAULT_IMAGE,TESTIMONIAL_IMAGE,META_DEFAULT_IMAGE };

