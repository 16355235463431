import Header from "../../components/Header"
import Footer from "../../components/Footer"
import HomeBanner from "../../components/Elements/sections/home_banner"
import TreatmentLayout from "../../components/Elements/sections/home_treatment_layout"
import HomeaboutUs from "../../components/Elements/sections/home_aboutUs"
import SuccessStories from "../../components/Elements/sections/success_stories"
import CrossingBoundries from "../../components/Elements/sections/crossing_boundries"
import Home_Bookappointment from "../../components/Elements/sections/home_book_appointment"
import Cardicintervention from "../../components/Elements/sections/cardic_intervention"
import FAQ from "../../components/Elements/sections/faq"
import HomeBlogs from "../../components/Elements/sections/home-blogs"
import HomeTestimonial from "../../components/Elements/sections/home_testimonial"
import { useEffect, useRef, useState } from "react"
import { ApiService } from "../../components/services/apiServices"
import constants from "../../components/services/constants"
import { Helmet } from "react-helmet"
import HomeVideo from "../../components/Elements/sections/home_video"


const Home=()=>{
    
    const [pageContent, setpageContent] = useState({});
    const[headerImageUrl,setHeaderImageUrl] = useState('')
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getPagesData()
        }
        didMountRef.current = false;
      }, []);

      const getPagesData = () => {
        const pageContent = {
          slug: "home",
        };
    
        ApiService.Commanpostrequest("pages-content", pageContent).then((res) => {
          if (res.status == "success") {
            setpageContent(res.data)
            setHeaderImageUrl(res.header_image_path)
          }
        });
      };
    return(
    <>
        <Helmet>
      <title>{pageContent.page_meta_title}</title>
      <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
      {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:""}
      <link rel="canonical" href={window.location.href} />
      <meta property="og:title" content={pageContent.page_meta_title} />
      <meta property="og:image" content= {constants.FRONT_URL+'images/logo.png'}/>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content= {pageContent.page_meta_desc != null ?pageContent.page_meta_desc:"Dr Prashant Dwivedi"} />
      <meta name="twitter:title" content={pageContent.page_meta_title} />
      <meta name="twitter:description" content={pageContent.page_meta_desc != null ?pageContent.page_meta_desc:"Dr Prashant Dwivedi"} />
      <meta property="twitter:image" content={constants.FRONT_URL + 'images/logo.png'} />
      <meta name="twitter:url" content={window.location.href} />
      </Helmet>
    <Header></Header>
    <div>
        <HomeBanner />
        <TreatmentLayout />
        <HomeaboutUs />
        <HomeVideo/>
        <SuccessStories />
        <CrossingBoundries />
        <Home_Bookappointment />
        <Cardicintervention />
        <HomeTestimonial></HomeTestimonial>
        <HomeBlogs />
        <FAQ />
    </div>

    <Footer></Footer>
    
    </>)
}

export default Home