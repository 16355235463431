import React, { useEffect, useRef, useState, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../services/apiServices";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


const Footer = () => {
  const [settingData, setSettingData] = useState();
  const [footerData, setFooterData] = useState();
  const [footer1, setFooter1] = useState("");
  const [footer2, setFooter2] = useState("");
  const [footer3, setFooter3] = useState("");
  const [footer4, setFooter4] = useState("");
  const [imagePath, setImagePath] = useState("");

  const didMountRef = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) {
      getSettingData();
      getFooterData();
    }
    didMountRef.current = false;
  }, []);

  const getFooterData = () => {
    ApiService.Commanfetchrequest("footerdata").then((response) => {
     
      if (response?.status === "success") {
        setFooterData(response?.data);
        setFooter1(response?.data.footer_desc1);
        setFooter2(response?.data.footer_desc2);
        setFooter3(response?.data.footer_desc3);
        setFooter4(response?.data.footer_desc4);
      }
    });
  };

  const getSettingData = () => {
    ApiService.Commanfetchrequest("settingdata").then((res) => {
     
      if (res?.status == "success") {
        setSettingData(res?.data);
        setImagePath(res?.setting_image_path);
      }
    });
  };

  return (
    <>
      <BrowserView>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-6 border-right">
                <div className="row justify-content-between">
                  <div className="col-lg-6">
                    <a href="/">
                      <img
                        src={
                          settingData?.footer_logo
                            ? imagePath + settingData?.footer_logo
                            : "/images/logo_white.png"
                        }
                        className="footer-logo"
                        alt="Dr. Prashant Dwivedi"
                      ></img>
                    </a>
                    <div className="contactinfo mt-4">
                      <ul>
                        <li>
                          <a
                            href="https://maps.app.goo.gl/k8wNf4o6AoBBYdR86"
                            target="new"
                          >
                            <span className="cicon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </span>
                            <span className="ccontent">
                              {settingData?.address}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href={"tel:" + settingData?.admin_support_mobile}>
                            <span className="cicon">
                              <i class="icon-phone"></i>
                            </span>
                            <span className="ccontent">
                              {settingData?.admin_support_mobile}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={"mailto:" + settingData?.admin_support_email}
                          >
                            <span className="cicon">
                              <i class="icon-envelope"></i>
                            </span>
                            <span className="ccontent">
                              {settingData?.admin_support_email}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <h4 className="footer-title">Follow Us</h4>
                    <ul className="socialmedia mb-4">
                      {settingData?.facebook_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.facebook_url} target="new">
                              <i class="icon-facebook"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.twitter_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.twitter_url} target="new">
                              <i class="icon-twitter"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.instagram_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.instagram_url} target="new">
                              <i class="icon-instagram"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.linkedin_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.linkedin_url} target="new">
                              <i class="icon-linkedin2"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.youtube_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.youtube_url} target="new">
                              <i class="icon-youtube"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                    <button
                      className="edu-btn btn-medium"
                      onClick={() => navigate("/contact-us")}
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ps-4">
                  <div className="row">
                    <div className="col-lg-4">
                      <h4 className="footer-title">Quick Links</h4>

                      <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                    </div>
                    <div className="col-lg-8">
                      <h4 className="footer-title">Treatments</h4>

                      <div dangerouslySetInnerHTML={{ __html: footer2 }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <p className="tx-12">
                    {" "}
                    Disclaimer: The information on the Web Site is provided for
                    informational purposes only and is not meant to substitute
                    the advice provided by our doctor or other health care
                    professional. You should not use the information available
                    on or through the Web Site for treating a health problem or
                    disease or prescribing any medication. All images used on
                    this website are for illustrative purposes only,
                  </p>
                  <p className="mb-0">
                    © Copyright 2024 Design by Polysol Infotech | All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </BrowserView>
      <MobileView>
        <footer className="footer">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <div className="row justify-content-between">
                  <div className="whatsapp-mobile"
                  style={{
                    bottom:"20px",
                    left:"83%",
                    position:"fixed",
                    zIndex:"9999"
                  }}
                  >
                    <a href={"https://wa.me/"+settingData?.admin_support_mobile} target="new">
                      <img src="/images/whatsapp1.png" width={"50px"} height={"50px"}/>
                    </a>
                  </div>

                  <div className="col-lg-6">
                    <a href="/">
                      <img
                        src={
                          settingData?.footer_logo
                            ? imagePath + settingData?.footer_logo
                            : "/images/logo_white.png"
                        }
                        className="footer-logo"
                      ></img>
                    </a>
                    <div className="contactinfo mt-4">
                      <ul>
                        <li>
                          <a
                            href="https://maps.app.goo.gl/k8wNf4o6AoBBYdR86"
                            target="new"
                          >
                            <span className="cicon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            </span>
                            <span className="ccontent">
                              {settingData?.address}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href={"tel:" + settingData?.admin_support_mobile}>
                            <span className="cicon">
                              <i class="icon-phone"></i>
                            </span>
                            <span className="ccontent">
                              {settingData?.admin_support_mobile}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={"mailto:" + settingData?.admin_support_email}
                          >
                            <span className="cicon">
                              <i class="icon-envelope"></i>
                            </span>
                            <span className="ccontent">
                              {settingData?.admin_support_email}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center">
                    <hr></hr>
                    <h4 className="footer-title text-center">Follow Us</h4>
                    <ul className="socialmedia mb-4">
                      {settingData?.facebook_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.facebook_url} target="new">
                              <i class="icon-facebook"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.twitter_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.twitter_url} target="new">
                              <i class="icon-twitter"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.instagram_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.instagram_url} target="new">
                              <i class="icon-instagram"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.linkedin_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.linkedin_url} target="new">
                              <i class="icon-linkedin2"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {settingData?.youtube_url != null ? (
                        <>
                          <li>
                            <a href={settingData?.youtube_url} target="new">
                              <i class="icon-youtube"></i>
                            </a>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                    <button
                      className="edu-btn btn-medium mb-4"
                      onClick={() => navigate("/contact-us")}
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Quick Links</Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: footer1 }}></div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Treatments</Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: footer2 }}></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="copyright text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <p className="tx-12">
                    {" "}
                    Disclaimer: The information on the Web Site is provided for
                    informational purposes only and is not meant to substitute
                    the advice provided by our doctor or other health care
                    professional. You should not use the information available
                    on or through the Web Site for treating a health problem or
                    disease or prescribing any medication. All images used on
                    this website are for illustrative purposes only,
                  </p>
                  <p className="mb-0">
                    © Copyright 2024 Design by Polysol Infotech | All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </MobileView>
    </>
  );
};

export default Footer;
