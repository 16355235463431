import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../services/apiServices";
import constants from "../../services/constants";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
const SuccessStories = () => {
  const defaultImg = constants.DEFAULT_IMAGE;
  const [Successtories, setsuccessstories] = useState([]);
  const [videoUrl, setvideoUrl] = useState("");
  const [ImgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState();
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      Successstories();
    }
    didMountRef.current = false;
  }, []);

  const Successstories = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("storiesdata").then((res) => {
     
      if (res?.status == "success") {
        setsuccessstories(res?.data);
        setImgUrl(res?.STORY_IMAGE_URL);
        setvideoUrl(res?.data[0]);
        setLoading(false)
      }
    });
  };
  const getImageUrl = (id) => {
    const imageObj = Successtories.find((item) => item.id === id);
    // return imageObj ? imageObj.vedio : '';
    setvideoUrl(imageObj);
    // Return the URL or an empty string if not found
  };
  return (
    <>
           {loading == true ? (
          <>
            <section className="section-gap-md blog-section-2">
              <div className="container-fluid">
                <div className="section-title section-center">
                  <h2><Skeleton width={"40%"} height={40}/></h2>
                  <span class="shape-line">
                   <Skeleton width={100} height={10}/>
                  </span>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="blog blog-style-2 first-large-blog">
                      <div className="thumbnail">
                        <a href="javascriptvoid(0)">
                          <Skeleton width={"100%"} height={400} />
                          {/* <iframe
                                            width="640"
                                            height="360"
                                            src={videoUrl?.video}
                                            title={videoUrl?.title}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen

                                        ></iframe> */}
                        </a>
                      </div>
                      <div className="content">
                        <h2 className="title">
                          <Skeleton width={"100%"} height={50} />
                        </h2>
                        <p className="mb-0">
                          <Skeleton width={"100%"} height={10} />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="blog blog-style-2 second-large-blog">
                      {[...Array(4)].map((_, index) => (
                        <>
                          <div className="inner" key={index}>
                            <div className="thumbnail">
                            <a href="javascriptvoid(0)">
                                <Skeleton width={"100%"} height={120} />
                              </a>
                            </div>
                            <div className="content">
                              <h2 className="title-small">
                                <Skeleton width={"100%"} height={40} />
                              </h2>
                              {/* <p className='mb-2'>{items?.stories_desc}</p> */}

                              <p className="mb-0">
                                <Skeleton width={"100%"} height={10} />
                              </p>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className="section-gap-md blog-section-2">
              <div className="container-fluid">
                <div className="section-title section-center">
                  <h2>Success Stories</h2>
                  <span class="shape-line">
                    <i class="icon-19"></i>
                  </span>
                </div>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="blog blog-style-2 first-large-blog">
                      <div className="thumbnail">
                         <a href="javascript:void(0)">
                          <img
                            src={
                              videoUrl?.image
                                ? ImgUrl + videoUrl?.image
                                : defaultImg
                            }
                          ></img>
                          {/* <iframe
                                            width="640"
                                            height="360"
                                            src={videoUrl?.video}
                                            title={videoUrl?.title}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen

                                        ></iframe> */}
                        </a>
                      </div>
                      <div className="content">
                        <h2 className="title">{videoUrl?.title}</h2>
                        <p className="mb-0">
                          {moment(videoUrl?.created_at).format(`MMMM Do YYYY`)}
                        </p>
                      </div>
                    </div>
                  </div>
                  {Successtories?.length > 0 ? (
                    <>
                      <div className="col-lg-5"  style={{ maxHeight: '460px', overflowY: 'auto' }}>
                        {Successtories?.map((items, index) => {
                          return (
                            <>
                              <div
                                className="blog blog-style-2 second-large-blog"
                                key={index}
                                onClick={() => {
                                  getImageUrl(items?.id);
                                }}
                               
                              >
                                <div className="inner" >
                                  <div className="thumbnail">
                                    <a href="javascript:;">
                                      <img
                                        src={
                                          items?.image !== ""
                                            ? ImgUrl + items.image
                                            : defaultImg
                                        }
                                      ></img>
                                    </a>
                                  </div>
                                  <div className="content">
                                    <h2 className="title-small">
                                    <a href="javascript:;" style={{color:"#000"}}>     {items?.title}         </a>
                                    </h2>
                                    {/* <p className='mb-2'>{items?.stories_desc}</p> */}

                                    <p className="mb-0">
                                      {moment(items?.created_at).format(
                                        `MMMM Do YYYY`
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          </>
        )}
    </>
  );
};

export default SuccessStories;
