import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ApiService } from "../../components/services/apiServices";
import { useParams } from "react-router-dom";
import constants from "../../components/services/constants";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

function BlogsDetail() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [blogData, setBlogData] = useState([]);
  const [defaultImage, setDefaultImage] = useState("");
  const [blogDetail, setBlogDetail] = useState({});
  const [blogImageUrl, setBlogImageUrl] = useState({});
  const [sheaderimg, setSheaderImg] = useState("");
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [latestCategoryData, setLatestCategoryData] = useState([]);
  const [latestBlogImageUrl, setLatestBlogImageUrl] = useState("");
  const [blogListDefaultImage, setBlogListDefaultImage] = useState("");
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (didMountRef.current) {
      getBlogDetail();
      latestBlogs();
    }
    didMountRef.current = false;
  });
  const latestBlogs = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("blog-list-sidebar-data").then((res) => {
      if (res?.status === "success") {
        setLatestCategoryData(res.blogCategories);
        setLatestBlogData(res.latestblogdata);
        setLatestBlogImageUrl(res.blog_url);
        setBlogListDefaultImage(res.default_image_baseurl);
        setLoading(false);
      }
    });
  };
  const getBlogDetail = () => {
    const blogs = {
      blog_slug: slug,
    };
    setLoading(true);
    ApiService.Commanpostrequest("blogs-details", blogs).then((res) => {
      if (res.status == "success") {
        setBlogDetail(res.blogDetail);
        setBlogImageUrl(res.blog_img_url);
        setSheaderImg(res.blogDetail.blog_thumbnail_image);
        setFaq(res.blogFaqData);
        setLoading(false);
      }
    });
  };

  const categorywisebloglist = (category_slug) => {
    const dataString = {
      category_slug: category_slug,
    };
    setLoading(true);
    ApiService.Commanpostrequest("category-wise-blogs-list", dataString).then(
      (res) => {
        if (res?.status === "success") {
          setBlogData(res.blogsData);
          setBlogImageUrl(res.blog_url);
          setDefaultImage(res.default_image_baseurl);
          setLoading(false);
        }
      }
    );
  };

  return (
    <>
            <Helmet>
        <title>{blogDetail.blog_meta_title}</title>
        <meta name="description" itemprop="description" content={blogDetail.blog_meta_desc != null ? blogDetail.blog_meta_desc :"Dr Prashant Dwivedi"} />
        {blogDetail.blog_meta_keyword != null ?<meta name="keywords" content={blogDetail.blog_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={blogDetail.blog_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={blogDetail.blog_image ? blogImageUrl + blogDetail.blog_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {blogDetail.blog_meta_desc != null ?<meta property="og:description" content={blogDetail.blog_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={blogDetail.blog_meta_title} />
        {blogDetail.blog_meta_desc != null ?<meta name="twitter:description" content={blogDetail.blog_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image"  content={blogDetail.blog_image ? blogImageUrl + blogDetail.blog_image: constants.META_DEFAULT_IMAGE}/>
        
      </Helmet>
      <Header />
      {loading == true ? (
        <>
          <div className="subheader smallsubheader">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <nav aria-label="breadcrumb">
                    <Skeleton width={"18%"} height={18} />
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <section className="section-gap-md">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="bloglistbox bloglistbox-details">
                    <div className="bloglistbox-thumb">
                      <Skeleton width={"100%"} height={400} />
                    </div>
                    <div className="bloglistbox-content">
                      <h2 className="title">
                        <Skeleton width={"100%"} height={20} />
                      </h2>
                      <p>
                        {" "}
                        <Skeleton width={"100%"} height={100} />
                      </p>
                    </div>
                  </div>

                  <div className="blog-accordion">
                    <div className="section-title section-center">
                      <h2>
                        <Skeleton width={"100%"} height={25} />
                      </h2>
                    </div>
                    <Accordion>
                      <Skeleton width={"100%"} height={50} />
                    </Accordion>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="sidebarbox">
                    <h2 className="title">
                      <Skeleton width={"100%"} height={24} />
                    </h2>

                    <div className="ltblog">
                      <div className="ltblog-img">
                        <Skeleton width={"100%"} height={100} />
                      </div>
                      <div className="ltblog-content">
                        <div className="listmeta">
                          <ul>
                            <li>
                              <Skeleton width={"100%"} height={10} />
                            </li>
                          </ul>
                        </div>
                        <h2 className="title">
                          <a>
                            <Skeleton width={"50%"} height={25} />
                          </a>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="sidebarbox">
                    <h2 className="title">
                      <Skeleton width={"50%"} height={25} />
                    </h2>
                    <div className="sidelist">
                      <ul>
                        <li>
                          <a>
                            <Skeleton width={"100%"} height={20} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className="subheader smallsubheader">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {blogDetail.blog_name}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <section className="section-gap-md">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="bloglistbox bloglistbox-details">
                    <div className="bloglistbox-thumb">
                      <img
                        src={
                          blogDetail.blog_image != null
                            ? blogImageUrl + blogDetail.blog_image
                            : constants.DEFAULT_IMAGE
                        }
                        alt="blog_image"
                      />
                    </div>
                    <div className="bloglistbox-content">
                      <h2 className="title">{blogDetail?.blog_name}</h2>
                      <div className="listmeta mb-3">
                            <ul>
                              <li className="large">
                                <i class="icon-27"></i>
                                {moment(blogDetail.created_at).format("DD-MM-YYYY")}
                              </li>
                              <li className="large">
                                <i class="icon-25"></i> by admin
                              </li>
                            </ul>
                          </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: blogDetail.blog_desc,
                        }}
                      ></p>
                    </div>
                  </div>


                  {faq.length > 0 ?
                  <div className="blog-accordion mb-5">
                    <div className="section-title section-center">
                      <h2>Frequently Asked Questions</h2>
                      <span class="shape-line">
                        <i class="icon-19"></i>
                      </span>
                    </div>
                    <div className="accordbox">
                    <Accordion defaultActiveKey="0" flush>
                      {faq.map((item, index) => {
                        return (
                          <>
                            <Accordion.Item eventKey={index.toString()}>
                              <Accordion.Header>
                                {item?.bf_question}
                              </Accordion.Header>
                              <Accordion.Body>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.bf_answer,
                                  }}
                                ></p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        );
                      })}
                    </Accordion>
                  </div>

                  </div>
                  :""}
                </div>

                <div className="col-lg-4">
                  <div className="sidebarbox">
                    <h2 className="title">Latest Blog</h2>
                    {latestBlogData.map((value) => (
                      <div className="ltblog">
                        <div className="ltblog-img">
                          <img
                            src={
                              value.blog_image != null
                                ? latestBlogImageUrl + value.blog_image
                                : ""
                            }
                            alt="Blog Images"
                          ></img>
                        </div>
                        <div className="ltblog-content">
                          <div className="listmeta">
                            <ul>
                              <li>
                                <i class="icon-27"></i>
                                {moment(value.created_at).format("DD MMM YYYY")}
                              </li>
                            </ul>
                          </div>
                          <h2 className="title">
                            <a href={`/blogs/${value.blog_slug}`}>
                              {value.blog_name}
                            </a>
                          </h2>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="sidebarbox">
                    <h2 className="title">Categories</h2>
                    <div className="sidelist">
                      <ul>
                        {latestCategoryData.map((value) => (
                          <li
                            onClick={(e) =>
                              categorywisebloglist(value.category_slug)
                            }
                          >
                            <a href={`/blogs/category/` + value.category_slug}>
                              {value.category_name}{" "}
                              {value.blog_count != 0 ? (
                                <span>({value.blog_count})</span>
                              ) : (
                                ""
                              )}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
}

export default BlogsDetail;
