import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import { ApiService } from "../../components/services/apiServices";
import { useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";
function Treatments() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [treatmentData, setTreatmentData] = useState({});
  const [headerImage, setHeaderImage] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [Faq, setfaq] = useState([]);
  const [testimonialdata, settestimonialdata] = useState([]);
  const [testimonialImagepath, setTestimonialImagepath] = useState("");
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (didMountRef.current) {
      getTreatmentData();
    }
    didMountRef.current = false;
  });
  const generateRatingIcons = (rating) => {
    const icons = [];
    for (let i = 0; i < rating; i++) {
      icons.push(<i key={i} className="icon-23"></i>);
    }
    return icons;
  };
  const getTreatmentData = () => {
    const treatment = {
      treatment_slug: slug,
    };
    ApiService.Commanpostrequest("treatment-details", treatment).then((res) => {
      if (res.status == "success") {
        setTreatmentData(res.treatmentData);
        setHeaderImage(res?.treatmentData?.cover_image);
        setImageUrl(res.treatment_image_url);
        setfaq(res.faqData);
        settestimonialdata(res?.testimonialData);
        setTestimonialImagepath(res?.testimonial_image_url);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{treatmentData.meta_title}</title>
        <meta name="description" itemprop="description" content={treatmentData.meta_description != null ? treatmentData.meta_description : "Dr Prashant Dwivedi"} />
        {treatmentData.meta_keyword != null ? <meta name="keywords" content={treatmentData.meta_keyword} /> : "Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={treatmentData.meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image" content={headerImage ? imageUrl + headerImage : constants.META_DEFAULT_IMAGE} />
        <meta property="og:url" content={window.location.href} />
        {treatmentData.meta_description != null ? <meta property="og:description" content={treatmentData.meta_description} /> : "Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={treatmentData.meta_title} />
        {treatmentData.meta_description != null ? <meta name="twitter:description" content={treatmentData.meta_description} /> : "Dr Prashant Dwivedi"}
        <meta property="twitter:image" content={headerImage ? imageUrl + headerImage : constants.META_DEFAULT_IMAGE} />
      </Helmet>
      <Header />
      <div
        className="subheader"
        style={{ backgroundImage: `url(${imageUrl + headerImage})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{treatmentData?.title}</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {treatmentData?.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-gap-md">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <p
                dangerouslySetInnerHTML={{ __html: treatmentData?.description }}
              ></p>
            </div>
          </div>
        </div>
      </section>
      {testimonialdata.length > 0 && (
        <section
          className="section-gap-md"
          style={{ background: "rgb(246, 247, 248)" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title section-center">
                  <h2>Patients Testimonials</h2>
                  <span class="shape-line">
                    <i class="icon-19"></i>
                  </span>
                </div>
                <div className="testiomnial">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={4}
                    autoplay={{
                      delay: 4000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      400: {
                        slidesPerView: 1,
                      },
                      639: {
                        slidesPerView: 2,
                      },
                      865: {
                        slidesPerView: 2,
                      },
                      1000: {
                        slidesPerView: 4,
                      },
                      1500: {
                        slidesPerView: 4,
                      },
                      1700: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {testimonialdata.map((value, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="testimonial-grid">
                            <div className="thumbnail">
                              <img
                                src={
                                  value.testimonial_image != null
                                    ? testimonialImagepath +
                                    value.testimonial_image
                                    : constants.TESTIMONIAL_IMAGE
                                }
                                alt="Testimonial"
                              />
                              <span className="qoute-icon">
                                <i className="icon-26"></i>
                              </span>
                            </div>
                            <div className="content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: value.testimonial_desc,
                                }}
                              ></p>
                              <div className="rating-icon">
                                {generateRatingIcons(value.testimonial_rating)}
                              </div>
                              <h5 className="title">
                                {value.testimonial_name}
                              </h5>
                              <span className="subtitle">
                                {value.testimonial_post}
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {Faq.length > 0 && (
        <section className="section-gap-md">
          <div className="container-fluid">
            <div className="section-title section-center">
              <h2>Frequently Asked Questions</h2>
              <span class="shape-line">
                <i class="icon-19"></i>
              </span>
            </div>

            <Accordion defaultActiveKey="0" flush>
              {Faq?.map((items, index) => {
                return (
                  <>
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>{items?.faq_title}</Accordion.Header>
                      <Accordion.Body>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: items?.faq_description,
                          }}
                        ></p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
}

export default Treatments;
