import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import { ApiService } from "../../components/services/apiServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";

function Contact() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [settingData, setSettingData] = useState();
  const [pageContent, setpageContent] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [contactInput, setContactInput] = useState({
    contact_name: "",
    contact_email: "",
    contact_message: "",
    contact_mobile: "",
  });

  useEffect(() => {
    if (didMountRef.current) {
      getPagesData();
      getSettingData();
    }
    didMountRef.current = false;
  });

  const getPagesData = () => {
    const pageData = {
      slug: "contact-us",
    };

    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setpageContent(res.data);
        setHeaderImageUrl(res.header_image_path);
      }
    });
  };

  const handleContact = (e) => {
    const { name, value } = e.target;

    setContactInput({ ...contactInput, [name]: value });
  };

  function isValidEmail(contact_email) {
    return /\S+@\S+\.\S+/.test(contact_email);
  }
  function isValidNumber(contact_mobile) {
    return /^(\+\d{1,3}[- ]?)?\d{10}$/.test(contact_mobile);
  }

  const Contactprocess = () => {
    if (contactInput.contact_name === "") {
      toast.error("Please Enter Name");
      return false;
    }
    if (contactInput.contact_email === "") {
      toast.error("Please Enter Email");
      return false;
    }
    if (!isValidEmail(contactInput.contact_email)) {
      toast.error("Please Enter Valid Email");
      return false;
    }
    if (contactInput.contact_mobile === "") {
      toast.error("Please Enter Mobile");
      return false;
    }

    if (!isValidNumber(contactInput.contact_mobile)) {
      toast.error("Please Enter Valid Mobile");
      return false;
    }

    ApiService.Commanpostrequest("contact-us", contactInput).then((res) => {
      if (res.status == "success") {
        setSuccessMessage(res.message);
        setContactInput({
          contact_name: "",
          contact_email: "",
          contact_message: "",
          contact_mobile: "",
        });
        setTimeout(() => {
          setSuccessMessage();
        }, 2000);
      } else {
        setErrorMessage(res.message);
        setTimeout(() => {
          setErrorMessage();
        }, 2000);
      }
    });
  };

  const getSettingData = () => {
    ApiService.Commanfetchrequest("settingdata").then((res) => {
     
      if (res?.status == "success") {
        setSettingData(res?.data);
        setImagePath(res?.setting_image_path);
      }
    });
  };

  return (
    <>
        <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ?<meta property="og:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ?<meta name="twitter:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        
      </Helmet>
      <Header></Header>
      <div
        className="subheader"
        style={{
          backgroundImage: `url(${
            headerImageUrl + pageContent.page_header_image
          })`,
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h1>{pageContent.page_name}</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {pageContent.page_name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-gap-md">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div class="section-title mb-4">
              {errorMessage && (
                <Alert variant="danger" className="text-danger"> {errorMessage}</Alert>
              )}
              {successMessage && (
                <Alert  variant = "success" className="text-success"> {successMessage}</Alert>
              )}
                <span class="pre-title">Contact us</span>
                <h2 className="">Get In Touch With Us</h2>
                <p className="mb-0 mt-0">
                  Complete our online enquiry form located below and we will get
                  back to you as soon as possible.
                </p>
              </div>
             
              <div className="row g-2">
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Name</label>
                    <input
                      type="text"
                      name="contact_name"
                      value={contactInput.contact_name}
                      onChange={(e) => {
                        handleContact(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="contact_email"
                      value={contactInput.contact_email}
                      onChange={(e) => {
                        handleContact(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      name="contact_mobile"
                      value={contactInput.contact_mobile}
                      onChange={(e) => {
                        handleContact(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Message</label>
                    <textarea
                      name="contact_message"
                      value={contactInput.contact_message}
                      onChange={(e) => {
                        handleContact(e);
                      }}
                    ></textarea>
                  </div>
                </div>
                <div
                  className="col-lg-12"
                  onClick={() => {
                    Contactprocess();
                  }}
                >
                  <a class="edu-btn btn-medium">
                    Submit <i class="icon-4"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="gmap">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14239.956273156813!2d75.805812!3d26.8403!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5e5516f1531%3A0x947f210590b0e5b6!2sDr.%20Prashant%20Dwivedi%2C%20DM%20(Cardiology-Gold%20Medal)%2C(Expert%20in%20(TAVI%2FTAVR)%2C%20TMVR%2C%20MITRA-CLIP%20%26%20Complex%20and%20Primary%20Angioplasty)!5e0!3m2!1sen!2sin!4v1707472423958!5m2!1sen!2sin"
                  width="100%"
                  height="650"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gap-md contactsect">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contacts-item">
                <div className="contacts-item-icon">
                  <img src="/images/telephone.png" />
                </div>
                <div className="contacts-content">
                  <a href={"tel:" + settingData?.admin_support_mobile} className="title">
                  {settingData?.admin_support_mobile}
                  </a>
                  <p className="sub-title mb-0">Mon-Fri 9am-6pm</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contacts-item">
                <div className="contacts-item-icon">
                  <img src="/images/location.png" />
                </div>
                <div className="contacts-content">
                  <a href="https://maps.app.goo.gl/k8wNf4o6AoBBYdR86"  target="new" className="title">
                  {settingData?.address}
                  </a>
                  <p className="sub-title mb-0">
                  Mon-Fri 9am-6pm
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contacts-item">
                <div className="contacts-item-icon">
                  <img src="/images/email.png" />
                </div>
                <div className="contacts-content">
                  <a href={"mailto:" + settingData?.admin_support_email} className="title">
                  {settingData?.admin_support_email}
                  </a>
                  <p className="sub-title mb-0">Mon-Fri 9am-6pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Contact;
