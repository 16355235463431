import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import { ApiService } from "../../components/services/apiServices";
import { Accordion } from "react-bootstrap";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";

function AboutUs() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [pageContent, setpageContent] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  useEffect(() => {
    if (didMountRef.current) {
      getPagesData();
    }
    didMountRef.current = false;
  });

  const getPagesData = () => {
    const pageData = {
      slug: "about",
    };

    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setpageContent(res.data);
        setHeaderImageUrl(res.header_image_path);
      }
    });
  };

  return (
    <>
        <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ?<meta property="og:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ?<meta name="twitter:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>

        
      </Helmet>
      <BrowserView>
        <Header />

        <div
          className="subheader"
          style={{
            backgroundImage: `url(${
              headerImageUrl + pageContent.page_header_image
            })`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1>{pageContent.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {pageContent.page_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-md">
          <div className="container-fluid">
            <div className="row"
              dangerouslySetInnerHTML={{ __html: pageContent.page_content }}
            ></div>
          
        
            
          </div>
        </section>

        <section className='section-gap-md' style={{background:'rgb(246, 247, 248)'}}>
        <div className='container-fluid'>

        <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
                <Accordion.Header>ACADEMIC RECORD</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>
                          Fellowship Structural Heart Intervention, 2021-2022
                        </b>{" "}
                        <br />
                        Mount Sinai Hospital, New York, USA
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Fellowship Interventional Cardiology, 2020-2021</b>{" "}
                        <br />
                        Mount Sinai Hospital, New York, USA
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Fellowship DM Cardiology - 2013 – 2016</b> <br />
                        King George Medical University, Lucknow, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Senior Residency, Internal Medicine - 2012- 2013</b>{" "}
                        <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>MD (Residency), Internal Medicine - 2009- 2012</b>{" "}
                        <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Internship – 2008 - 2009</b> <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>MBBS - 2003 - 2008</b> <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>WORK EXPERIENCE</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      <b>
                        In charge &amp; Head, TAVR and Structural Heart Program,
                        Consultant Interventional Cardiology, since December
                        2022
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>
                        Associate Director TAVR and Structural Heart Program,
                        Consultant Interventional Cardiology, since July 2022
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>Consultant, Interventional Cardiology, 2016 – 2021</b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>
                        Proctor for TAVR across India &amp; International (MyVal
                        &amp; Medtronic Evolut){" "}
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>
                        Proctor for Rotational Atherectomy across India (Boston
                        Scientific)
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>AWARDS</Accordion.Header>
                <Accordion.Body>
                  <div class="field-body">
                    <p class="mb-4">Gold Medal in D.M (Cardiology)</p>
                    <p class="mb-4">Gold Medal in M.D (Internal Medicine)</p>
                    <p class="mb-4">
                      Best Case presentation in 6th Mid-term U.P Interventional
                      meet on 13/09/2015 organized at Lucknow by U.P Chapter,
                      Cardiology Society of India.
                    </p>
                    <p class="mb-4">
                      Achieved highest marks in D.M Cardiology in King George`s
                      Medical University, Lucknow, India
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>ACHIEVEMENTS</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      Among very few cardiologists in India who has been
                      dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip
                      and other structural heart interventions
                    </p>
                    <p className="mb-4">
                      Performed TMVR in Ring preceded by Alcohol septal ablation
                      with Sapien 3 Valve – First case in India
                    </p>
                    <p className="mb-4">
                      Performed open Trans atrial TMVR in MAC with
                      balloon-expandable valve (My-Val) - First case in India
                      (July 27, 2022)
                    </p>
                    <p className="mb-4">
                      Performed TAVR on the oldest patient in India (104 years
                      old) and 3rd oldest patient in the world (August 02, 2022)
                    </p>
                    <p className="mb-4">
                      Performed TAVR and Leadless Micra (Dual sensing) in same
                      sitting as a second case in India (February 23, 2022)
                    </p>
                    <p className="mb-4">
                      Achieved highest marks / Gold medal in D.M Cardiology in
                      King George`s Medical University, Lucknow, India
                    </p>
                    <p className="mb-4">
                      Awarded Gold medal by Mahatma Gandhi Memorial Medical
                      College, DAVV University, Indore, India for Best PG in
                      Internal Medicine
                    </p>
                    <p className="mb-4">
                      Best Case presentation in Interventional meet on September
                      13, 2015, by the cardiology society of India
                    </p>
                    <p className="mb-4">
                      Educational Commission for Foreign Medical Graduates
                      (ECFMG) Certified – December 18, 2019
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>AREA OF EXPERTISE</Accordion.Header>
                <Accordion.Body>
                  <div classname="field-body">
                    <p className="mb-4">
                      Transcatheter Aortic Valve Replacement (TAVR/TAVI)-
                      Experience of more than 300 TAVR cases, including Bicuspid
                      valve, Valve in Valve, TAVR in TAVR
                    </p>
                    <p className="mb-4">
                      Transcatheter Edge to Edge repair (TEER) - One of the very
                      few cardiologists in India having expertise in Mitra-Clip,
                      Tri-Clip
                    </p>
                    <p className="mb-4">TMVR, TPVR, TTVR</p>
                    <p className="mb-4">
                      Complex coronary angioplasty including Impella,
                      Rotablation, Orbital atherectomy, LASER, IVL
                    </p>
                    <p className="mb-4">Left Atrial Appendage device closure</p>
                    <p className="mb-4">Paravalvular Leak closure</p>
                    <p className="mb-4">Alcohol Septal ablation</p>
                    <p className="mb-4">PFO/ASD/VSD/PDA/RSOV device closure</p>
                    <p className="mb-4">BMV/BAV/BPV/Coarctoplasty</p>
                    <p className="mb-4">
                      Peripheral intervention including Carotid, Renal, and
                      Ileo-femoral intervention
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>PRESENTATIONS</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      Case presented on “A case of stuck un-deflated coronary
                      balloon” in the “10th India Live Interventional Cardiology
                      Conference” on 2nd March 2019, organized by the
                      Interventional cardiology foundation of India (ICFI)
                    </p>
                    <p className="mb-4">
                      Case presented on “A case of futility…...never give up!! A
                      case of VT storm with rescue PCI of LM bifurcation in
                      ongoing CPR and recurrent DC shock” in the “10th India
                      Live Interventional Cardiology Conference” on 1st March
                      2019, organized by Interventional cardiology foundation of
                      India (ICFI)
                    </p>
                    <p className="mb-4">
                      Challenging case presented on “Maze of Haze – A case of
                      ECMO &amp; IABP assisted Rota PCI of LM bifurcation in
                      ACS” in ‘‘Transcatheter Cardiovascular Therapeutics [TCT]
                      2018 conference’’ held at San Diego, USA from 21st to 25th
                      September 2018, organized by Cardiovascular Research
                      Foundation
                    </p>
                    <p className="mb-4">
                      Case presented on “Valve in valve Transcatheter Mitral
                      Valve Replacement (TMVR)” in the “India Valve 2018
                      Conference” held in New Delhi, India from 31st August to
                      2nd September 2018, organized by Heart Valve Foundation of
                      India
                    </p>
                    <p className="mb-4">
                      Case presented on “Rota-ablation guided complex PCI to LCX
                      and RCA” in 8th India Live Interventional Cardiology
                      conference on 3rd March 2017 organized by Interventional
                      cardiology foundation of India (ICFI)
                    </p>
                    <p className="mb-4">
                      Case presented on “ECMO assisted Rota-ablation PCI of LMCA
                      and RCA” in 8th India Live Interventional Cardiology
                      conference on 2nd March 2017 organized by Interventional
                      cardiology foundation of India (ICFI)
                    </p>
                    <p className="mb-4">
                      Case presented on “Unusual wire entrapment with fracture
                      during PCI “in 6th Midterm U.P Interventional meet on 13th
                      September 2015 organized at Lucknow by U.P chapter,
                      cardiology society of India
                    </p>
                    <p className="mb-4">
                      Case presented on Scleroderma in: “Workshop of
                      Rheumatology”, March 2012 organized by Department of
                      Medicine, MGM Medical College, Indore, India
                    </p>
                    <p className="mb-4">
                      Case presented in 3rd Annual Refresher course in:
                      “Clinical Examination and Interpretation” January 2012
                      organized by Department of Medicine, MGM Medical College,
                      Indore, India
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  PUBLICATIONS & RESEARCH EXPERIENCE
                </Accordion.Header>
                <Accordion.Body>
                  <div class="field-body">
                    <p class="mb-4">
                      Paper publication on “ Efficacy and Safety of an Upfront
                      RotaTripsy Strategy in the Treatment of DeNovo and
                      In-stent Restenosis” in J INVSIVE CARDIOL 2023;35(2):
                      E70-E74.Epub 2023 January 6.
                    </p>
                    <p class="mb-4">
                      Paper publication on “Single centre experience of 105
                      minimalistic transfemoral transcatheter aortic valve
                      replacement and its outcome” in Indian Heart Journal
                      Volume 73, Issue 3, May-June 2021
                    </p>
                    <p class="mb-4">
                      Paper publication on “Bifurcation Stenting: Basics and
                      Beyond” in NIC newsletter organized by National
                      interventional council &amp; Cardiology Society of India,
                      held on 05th to 7th April 2019 at Lucknow, India
                    </p>
                    <p class="mb-4">
                      Paper publication on “Biochemical and blood profile
                      amongst hypertensive subjects reporting at a tertiary care
                      hospital” in International Journal of Medical Research
                      Professionals, DOI: 10.21276/ijmrp.2018.4.6.033
                    </p>
                    <p class="mb-4">
                      Worked as Sub Investigator for Clinical Research protocol:
                      A Multicenter, randomized, double-blind,
                      placebo-controlled, 5-arm, parallel-group study to assess
                      Rotigotine transdermal system dose response in subjects
                      with advanced-stage Parkinson`s disease
                    </p>
                    <p class="mb-4">
                      Worked as Sub Investigator for Clinical Research protocol:
                      A Multicenter “IMPROVE BRADY” trial (study)
                    </p>
                    <p class="mb-4">
                      Worked as Sub Investigator for Clinical Research protocol:
                      A Multicenter “IMPROVE SCA” trial (study)
                    </p>
                    <p class="mb-4">
                      Performed Extensive Clinical Research in the field of
                      Systemic Hypertension. The research work was submitted as
                      an MD thesis entitled “Assessment of endothelial
                      dysfunction by Flow Mediated Dilation of the brachial
                      artery in young patients of systemic hypertension” in
                      M.G.M Medical College, DAVV University Indore, India
                    </p>
                    <p class="mb-4">
                      Performed Extensive Imaging and Clinical Research in the
                      field of Pulmonary Arterial Hypertension. The research
                      work was submitted as a D.M thesis entitled “Assessment of
                      short-term effects of phosphodiesterase -5 inhibitors in
                      patients with secondary pulmonary hypertension.” in King
                      George's Medical University, Lucknow, India
                    </p>
                    <p class="mb-4">
                      Abstract publication on “Assessment of short-term effects
                      of sildenafil therapy in patients with secondary pulmonary
                      hypertension” in Indian Heart Journal 67 (2015) S 124
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>TEACHING EXPERIENCE</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <span>
                      <strong>
                        Mahatma Gandhi Memorial Medical College, Indore, India
                      </strong>
                    </span>
                    <p className="mb-4">
                      Worked as a postgraduate cum tutor and was actively
                      involved in teaching medical and dental undergraduates
                      from 2009 to 2012
                    </p>
                    <p className="mb-4">
                      Worked as a senior resident cum tutor and was actively
                      involved in teaching postgraduate students of Internal
                      medicine from 2012 to 2013
                    </p>
                    <span>
                      <strong>
                        King George`s Medical University, Lucknow, India
                      </strong>
                    </span>
                    <p className="mb-4">
                      Worked as Cardiology Fellow cum tutor and was actively
                      involved in teaching medical undergraduates and
                      postgraduates from 2013 to 2016
                    </p>
                    <span>
                      <strong>Mount Sinai Hospital, New York, USA</strong>
                    </span>
                    <p className="mb-4">
                      Worked as Interventional cardiology / Structural heart
                      fellow cum tutor and was actively involved in teaching for
                      General Cardiology fellow from 2021 to June 2022
                    </p>
                    <span className>
                      <strong>Eternal Hospital Jaipur, India</strong>
                    </span>
                    <p className="mb-4">
                      Working as teaching faculty for DNB Cardiology fellow
                      since September 2019 to date
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header> MEMBERSHIP</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      Fellow American College of Cardiology (FACC / FIT)
                    </p>
                    <p className="mb-4">
                      Lifetime Member of the Cardiology Society of India (CSI)
                      (L-4245)
                    </p>
                    <p className="mb-4">
                      International Associate, Society of Cardiovascular
                      Angiography and Interventions (SCAI)
                    </p>
                    <p className="mb-4">
                      Member of the European Society of Cardiology (ESC)
                      (722180)
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


       </div>
        </section>


        <Footer></Footer>
      </BrowserView>

      <MobileView>
        <Header></Header>
        <div
          className="subheader"
          style={{
            backgroundImage: `url(${
              headerImageUrl + pageContent.page_header_image
            })`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1>{pageContent.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {pageContent.page_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-md">
          <div className="container-fluid">
            <div className="row"
              dangerouslySetInnerHTML={{ __html: pageContent.page_content }}
            ></div>
          
        
            
          </div>
        </section>
        <section className='section-gap-md' style={{background:'rgb(246, 247, 248)'}}>
        <div className='container-fluid'>

        <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
                <Accordion.Header>ACADEMIC RECORD</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>
                          Fellowship Structural Heart Intervention, 2021-2022
                        </b>{" "}
                        <br />
                        Mount Sinai Hospital, New York, USA
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Fellowship Interventional Cardiology, 2020-2021</b>{" "}
                        <br />
                        Mount Sinai Hospital, New York, USA
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Fellowship DM Cardiology - 2013 – 2016</b> <br />
                        King George Medical University, Lucknow, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Senior Residency, Internal Medicine - 2012- 2013</b>{" "}
                        <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>MD (Residency), Internal Medicine - 2009- 2012</b>{" "}
                        <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>Internship – 2008 - 2009</b> <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="mb-4">
                        <b>MBBS - 2003 - 2008</b> <br />
                        M.G.M Medical College &amp; DAVV, Indore, India
                      </p>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>WORK EXPERIENCE</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      <b>
                        In charge &amp; Head, TAVR and Structural Heart Program,
                        Consultant Interventional Cardiology, since December
                        2022
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>
                        Associate Director TAVR and Structural Heart Program,
                        Consultant Interventional Cardiology, since July 2022
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>Consultant, Interventional Cardiology, 2016 – 2021</b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>
                        Proctor for TAVR across India &amp; International (MyVal
                        &amp; Medtronic Evolut){" "}
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                    <p className="mb-4">
                      <b>
                        Proctor for Rotational Atherectomy across India (Boston
                        Scientific)
                      </b>{" "}
                      <br />
                      Eternal Hospital and Research Institute, Jaipur, India
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>AWARDS</Accordion.Header>
                <Accordion.Body>
                  <div class="field-body">
                    <p class="mb-4">Gold Medal in D.M (Cardiology)</p>
                    <p class="mb-4">Gold Medal in M.D (Internal Medicine)</p>
                    <p class="mb-4">
                      Best Case presentation in 6th Mid-term U.P Interventional
                      meet on 13/09/2015 organized at Lucknow by U.P Chapter,
                      Cardiology Society of India.
                    </p>
                    <p class="mb-4">
                      Achieved highest marks in D.M Cardiology in King George`s
                      Medical University, Lucknow, India
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>ACHIEVEMENTS</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      Among very few cardiologists in India who has been
                      dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip
                      and other structural heart interventions
                    </p>
                    <p className="mb-4">
                      Performed TMVR in Ring preceded by Alcohol septal ablation
                      with Sapien 3 Valve – First case in India
                    </p>
                    <p className="mb-4">
                      Performed open Trans atrial TMVR in MAC with
                      balloon-expandable valve (My-Val) - First case in India
                      (July 27, 2022)
                    </p>
                    <p className="mb-4">
                      Performed TAVR on the oldest patient in India (104 years
                      old) and 3rd oldest patient in the world (August 02, 2022)
                    </p>
                    <p className="mb-4">
                      Performed TAVR and Leadless Micra (Dual sensing) in same
                      sitting as a second case in India (February 23, 2022)
                    </p>
                    <p className="mb-4">
                      Achieved highest marks / Gold medal in D.M Cardiology in
                      King George`s Medical University, Lucknow, India
                    </p>
                    <p className="mb-4">
                      Awarded Gold medal by Mahatma Gandhi Memorial Medical
                      College, DAVV University, Indore, India for Best PG in
                      Internal Medicine
                    </p>
                    <p className="mb-4">
                      Best Case presentation in Interventional meet on September
                      13, 2015, by the cardiology society of India
                    </p>
                    <p className="mb-4">
                      Educational Commission for Foreign Medical Graduates
                      (ECFMG) Certified – December 18, 2019
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>AREA OF EXPERTISE</Accordion.Header>
                <Accordion.Body>
                  <div classname="field-body">
                    <p className="mb-4">
                      Transcatheter Aortic Valve Replacement (TAVR/TAVI)-
                      Experience of more than 300 TAVR cases, including Bicuspid
                      valve, Valve in Valve, TAVR in TAVR
                    </p>
                    <p className="mb-4">
                      Transcatheter Edge to Edge repair (TEER) - One of the very
                      few cardiologists in India having expertise in Mitra-Clip,
                      Tri-Clip
                    </p>
                    <p className="mb-4">TMVR, TPVR, TTVR</p>
                    <p className="mb-4">
                      Complex coronary angioplasty including Impella,
                      Rotablation, Orbital atherectomy, LASER, IVL
                    </p>
                    <p className="mb-4">Left Atrial Appendage device closure</p>
                    <p className="mb-4">Paravalvular Leak closure</p>
                    <p className="mb-4">Alcohol Septal ablation</p>
                    <p className="mb-4">PFO/ASD/VSD/PDA/RSOV device closure</p>
                    <p className="mb-4">BMV/BAV/BPV/Coarctoplasty</p>
                    <p className="mb-4">
                      Peripheral intervention including Carotid, Renal, and
                      Ileo-femoral intervention
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>PRESENTATIONS</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      Case presented on “A case of stuck un-deflated coronary
                      balloon” in the “10th India Live Interventional Cardiology
                      Conference” on 2nd March 2019, organized by the
                      Interventional cardiology foundation of India (ICFI)
                    </p>
                    <p className="mb-4">
                      Case presented on “A case of futility…...never give up!! A
                      case of VT storm with rescue PCI of LM bifurcation in
                      ongoing CPR and recurrent DC shock” in the “10th India
                      Live Interventional Cardiology Conference” on 1st March
                      2019, organized by Interventional cardiology foundation of
                      India (ICFI)
                    </p>
                    <p className="mb-4">
                      Challenging case presented on “Maze of Haze – A case of
                      ECMO &amp; IABP assisted Rota PCI of LM bifurcation in
                      ACS” in ‘‘Transcatheter Cardiovascular Therapeutics [TCT]
                      2018 conference’’ held at San Diego, USA from 21st to 25th
                      September 2018, organized by Cardiovascular Research
                      Foundation
                    </p>
                    <p className="mb-4">
                      Case presented on “Valve in valve Transcatheter Mitral
                      Valve Replacement (TMVR)” in the “India Valve 2018
                      Conference” held in New Delhi, India from 31st August to
                      2nd September 2018, organized by Heart Valve Foundation of
                      India
                    </p>
                    <p className="mb-4">
                      Case presented on “Rota-ablation guided complex PCI to LCX
                      and RCA” in 8th India Live Interventional Cardiology
                      conference on 3rd March 2017 organized by Interventional
                      cardiology foundation of India (ICFI)
                    </p>
                    <p className="mb-4">
                      Case presented on “ECMO assisted Rota-ablation PCI of LMCA
                      and RCA” in 8th India Live Interventional Cardiology
                      conference on 2nd March 2017 organized by Interventional
                      cardiology foundation of India (ICFI)
                    </p>
                    <p className="mb-4">
                      Case presented on “Unusual wire entrapment with fracture
                      during PCI “in 6th Midterm U.P Interventional meet on 13th
                      September 2015 organized at Lucknow by U.P chapter,
                      cardiology society of India
                    </p>
                    <p className="mb-4">
                      Case presented on Scleroderma in: “Workshop of
                      Rheumatology”, March 2012 organized by Department of
                      Medicine, MGM Medical College, Indore, India
                    </p>
                    <p className="mb-4">
                      Case presented in 3rd Annual Refresher course in:
                      “Clinical Examination and Interpretation” January 2012
                      organized by Department of Medicine, MGM Medical College,
                      Indore, India
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  PUBLICATIONS & RESEARCH EXPERIENCE
                </Accordion.Header>
                <Accordion.Body>
                  <div class="field-body">
                    <p class="mb-4">
                      Paper publication on “ Efficacy and Safety of an Upfront
                      RotaTripsy Strategy in the Treatment of DeNovo and
                      In-stent Restenosis” in J INVSIVE CARDIOL 2023;35(2):
                      E70-E74.Epub 2023 January 6.
                    </p>
                    <p class="mb-4">
                      Paper publication on “Single centre experience of 105
                      minimalistic transfemoral transcatheter aortic valve
                      replacement and its outcome” in Indian Heart Journal
                      Volume 73, Issue 3, May-June 2021
                    </p>
                    <p class="mb-4">
                      Paper publication on “Bifurcation Stenting: Basics and
                      Beyond” in NIC newsletter organized by National
                      interventional council &amp; Cardiology Society of India,
                      held on 05th to 7th April 2019 at Lucknow, India
                    </p>
                    <p class="mb-4">
                      Paper publication on “Biochemical and blood profile
                      amongst hypertensive subjects reporting at a tertiary care
                      hospital” in International Journal of Medical Research
                      Professionals, DOI: 10.21276/ijmrp.2018.4.6.033
                    </p>
                    <p class="mb-4">
                      Worked as Sub Investigator for Clinical Research protocol:
                      A Multicenter, randomized, double-blind,
                      placebo-controlled, 5-arm, parallel-group study to assess
                      Rotigotine transdermal system dose response in subjects
                      with advanced-stage Parkinson`s disease
                    </p>
                    <p class="mb-4">
                      Worked as Sub Investigator for Clinical Research protocol:
                      A Multicenter “IMPROVE BRADY” trial (study)
                    </p>
                    <p class="mb-4">
                      Worked as Sub Investigator for Clinical Research protocol:
                      A Multicenter “IMPROVE SCA” trial (study)
                    </p>
                    <p class="mb-4">
                      Performed Extensive Clinical Research in the field of
                      Systemic Hypertension. The research work was submitted as
                      an MD thesis entitled “Assessment of endothelial
                      dysfunction by Flow Mediated Dilation of the brachial
                      artery in young patients of systemic hypertension” in
                      M.G.M Medical College, DAVV University Indore, India
                    </p>
                    <p class="mb-4">
                      Performed Extensive Imaging and Clinical Research in the
                      field of Pulmonary Arterial Hypertension. The research
                      work was submitted as a D.M thesis entitled “Assessment of
                      short-term effects of phosphodiesterase -5 inhibitors in
                      patients with secondary pulmonary hypertension.” in King
                      George's Medical University, Lucknow, India
                    </p>
                    <p class="mb-4">
                      Abstract publication on “Assessment of short-term effects
                      of sildenafil therapy in patients with secondary pulmonary
                      hypertension” in Indian Heart Journal 67 (2015) S 124
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>TEACHING EXPERIENCE</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <span>
                      <strong>
                        Mahatma Gandhi Memorial Medical College, Indore, India
                      </strong>
                    </span>
                    <p className="mb-4">
                      Worked as a postgraduate cum tutor and was actively
                      involved in teaching medical and dental undergraduates
                      from 2009 to 2012
                    </p>
                    <p className="mb-4">
                      Worked as a senior resident cum tutor and was actively
                      involved in teaching postgraduate students of Internal
                      medicine from 2012 to 2013
                    </p>
                    <span>
                      <strong>
                        King George`s Medical University, Lucknow, India
                      </strong>
                    </span>
                    <p className="mb-4">
                      Worked as Cardiology Fellow cum tutor and was actively
                      involved in teaching medical undergraduates and
                      postgraduates from 2013 to 2016
                    </p>
                    <span>
                      <strong>Mount Sinai Hospital, New York, USA</strong>
                    </span>
                    <p className="mb-4">
                      Worked as Interventional cardiology / Structural heart
                      fellow cum tutor and was actively involved in teaching for
                      General Cardiology fellow from 2021 to June 2022
                    </p>
                    <span className>
                      <strong>Eternal Hospital Jaipur, India</strong>
                    </span>
                    <p className="mb-4">
                      Working as teaching faculty for DNB Cardiology fellow
                      since September 2019 to date
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header> MEMBERSHIP</Accordion.Header>
                <Accordion.Body>
                  <div className="field-body">
                    <p className="mb-4">
                      Fellow American College of Cardiology (FACC / FIT)
                    </p>
                    <p className="mb-4">
                      Lifetime Member of the Cardiology Society of India (CSI)
                      (L-4245)
                    </p>
                    <p className="mb-4">
                      International Associate, Society of Cardiovascular
                      Angiography and Interventions (SCAI)
                    </p>
                    <p className="mb-4">
                      Member of the European Society of Cardiology (ESC)
                      (722180)
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


       </div>
        </section>
        <Footer></Footer>
      </MobileView>
    </>
  );
}

export default AboutUs;
