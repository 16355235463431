import { BrowserView, MobileView } from 'react-device-detect'



const HomeaboutUs = () => {
    return (
    <>

        <section className='section-gap-md features_section cardicinv bg-image'>
            <div className='container-fluid'>
                <div className='section-title section-center'>
                    <span class="pre-title">"Your Heart Deserve The Best"</span>
                    <h2>Why you should choose Dr. Prashant Dwivedi</h2>
                    <span class="shape-line"><i class="icon-19"></i></span>
                    <p>We provide best cardic care, when you need it. Get Personlized and high quality care for your heart because.</p>
                </div>
                <div className='feature_detail'>
                    <div className='left_data feature_box'>
                        <div className='data_block aos-init'>
                            <div class="text">

                                <p>Among very few cardiologists in India who has been dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip and other structural heart interventions</p>

                            </div>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                        </div>
                        <div className='data_block aos-init'>
                            <div class="text">
                                <p>Performed TMVR in Ring preceded by Alcohol septal ablation with Sapien 3 Valve – First case in India</p>

                            </div>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                        </div>
                        <div className='data_block aos-init'>
                            <div class="text">
                                <p>Performed open Trans atrial TMVR in MAC with balloon-expandable valve (My-Val) - First case in India (July 27, 2022)
                                </p>

                            </div>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                        </div>
                        <div className='data_block aos-init'>
                            <div class="text">

                                <p>Performed TAVR on the oldest patient in India (104 years old) and 3rd oldest patient in the world (August 02, 2022)
                                </p>

                            </div>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                        </div>
                    </div>
                    <div className='right_data feature_box'>
                        <div className='data_block aos-init'>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                            <div class="text">

                                <p>Performed TAVR and Leadless Micra (Dual sensing) in same sitting as a second case in India (February 23, 2022)
                                </p>

                            </div>

                        </div>
                        <div className='data_block aos-init'>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                            <div class="text">

                                <p>Performed open Trans atrial TMVR in MAC with balloon-expandable valve (My-Val) - First case in India (July 27, 2022)</p>

                            </div>

                        </div>
                        <div className='data_block aos-init'>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                            <div class="text">

                                <p>Among very few cardiologists in India who has been dedicatedly trained in TAVR, TMVR, Mitra-Clip, Tri-Clip and other structural heart interventions</p>

                            </div>

                        </div>
                        <div className='data_block aos-init'>
                            <div className='ficon'>
                                <img src='/images/tick.png' />
                            </div>
                            <div class="text">

                                <p>Performed open Trans atrial TMVR in MAC with balloon-expandable valve (My-Val) - First case in India (July 27, 2022)</p>

                            </div>

                        </div>
                    </div>
                    <div className='feature_img aos-init'>
                        <img src='/images/drpstpic.png'></img>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default HomeaboutUs