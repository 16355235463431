import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiService } from "../../services/apiServices";

function Book_AppointmentModal({ show, handleClose }) {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [appointmentDetails, setAppointmentDetails] = useState({
    appointment_fname: "",
    appointment_lname: "",
    appointment_date: "",
    appointment_time: "",
    appointment_mobile: "",
    appointment_email: "",
    appointment_message: "",
  });

  const resetForm = () => {
    setAppointmentDetails({
      appointment_fname: "",
      appointment_lname: "",
      appointment_date: "",
      appointment_time: "",
      appointment_mobile: "",
      appointment_email: "",
      appointment_message: "",
    });
  };

  const bookAppointment = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      function emailRegex(appointment_email) {
        return /\S+@\S+\.\S+/.test(appointment_email);
      }
      function mobileRegex(appointment_mobile) {
        return /^(\+\d{1,3}[- ]?)?\d{10}$/.test(appointment_mobile);
      }

      if (!mobileRegex(appointmentDetails.appointment_mobile)) {
        toast.error("Please Enter 10 digit mobile number");
        return false;
      }
      if (!emailRegex(appointmentDetails.appointment_email)) {
        toast.error("Invalid Email! Please provide a valid email address.");
        return false;
      }

      ApiService.Commanpostrequest(
        "booking-appointment",
        appointmentDetails
      ).then((res) => {
        if (res.status == "success") {
          setSuccessMessage(res.message);
          resetForm();
          setTimeout(() => {
            setSuccessMessage();
          }, 3000);
        } else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setErrorMessage();
          }, 2000);
        }
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppointmentDetails({
      ...appointmentDetails,
      [name]: value,
    });

    const element = e.target;
    if (value !== "") {
      element.style.border = "";
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Book Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
              <div className="apponimentfBody">
                <div className="row g-3">
                  {errorMessage && (
                    <Alert variant="danger">{errorMessage}</Alert>
                  )}
                  {successMessage && (
                    <Alert variant="success">{successMessage}</Alert>
                  )}
                  <div className="col-lg-6">
                    <div className="form-group mb-1">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="appointment_fname"
                        value={appointmentDetails.appointment_fname}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        className="required"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-1">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="appointment_lname"
                        value={appointmentDetails.appointment_lname}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        className="required"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-1">
                      <label>Appointment Date</label>
                      <input
                        type="date"
                        name="appointment_date"
                        value={appointmentDetails.appointment_date}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        className="required"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-1">
                      <label>Appointment Time</label>
                      <input
                        type="time"
                        name="appointment_time"
                        value={appointmentDetails.appointment_time}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        className="required"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-1">
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        name="appointment_mobile"
                        value={appointmentDetails.appointment_mobile}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-1">
                      <label>Email Id</label>
                      <input
                        type="text"
                        name="appointment_email"
                        value={appointmentDetails.appointment_email}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-1">
                      <label>Message</label>
                      <textarea
                        name="appointment_message"
                        value={appointmentDetails.appointment_message}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <a class="edu-btn btn-medium" onClick={bookAppointment}>
                      Book Appointment<i class="icon-4"></i>
                    </a>
                  </div>
                </div>
              </div>
      
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Book_AppointmentModal;
