import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ApiService } from "../../services/apiServices";
import constants from "../../services/constants";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const TreatmentLayout = () => {
  const defaultImg = constants.DEFAULT_IMAGE;
  const [Treatmentdata, setTreatmentdata] = useState([]);
  const [ImgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState();
  const didMountRef = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) {
      treatmentList();
    }
    didMountRef.current = false;
  }, []);

  const treatmentList = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("treatmentsfeatured").then((res) => {
      if (res?.status == "success") {
        setTreatmentdata(res?.data);
        setImgUrl(res?.IMAGE_URL);
        setLoading(false);
      }
    });
  };
  return (
    <>
      <BrowserView>
        {loading == true ? (
          <>
            <section className="section-gap-md">
              <div className="container">
                <div className="section-title section-center">
                  <h2>
                    <Skeleton width={170} height={30} />
                  </h2>
                  <span class="shape-line">
                    <Skeleton width={100} height={10} />
                  </span>
                  <p>
                    <Skeleton width={"50%"} height={20} />
                  </p>
                </div>

                <div className="row g-3">
                  {[...Array(8)].map((_, index) => (
                    <div className="col-lg-3" key={index}>
                      <div className="treatbox">
                        <div className="treatbox-img">
                          <Skeleton width={"100%"} height={160} />
                        </div>
                        <div className="treatbox-title">
                          <Skeleton width={"100%"} height={30} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {Treatmentdata?.length > 0 ? (
              <>
                <section className="section-gap-md">
                  <div className="container">
                    <div className="section-title section-center">
                      <h2>Our Treatments</h2>
                      <span class="shape-line">
                        <i class="icon-19"></i>
                      </span>
                      <p>
                      Get personlized and high quality care for your heart beacause your best deserve the best.
                      </p>
                    </div>
                    <div className="row g-3 justify-content-center">
                      {Treatmentdata?.map((items, index) => {
                        return (
                          <>
                            <div className="col-lg-3" key={index}>
                              <div
                                className="treatbox"
                                onClick={() => {
                                  navigate(`/treatments/${items?.slug}`);
                                }}
                              >
                                <div className="treatbox-img">
                                  <img
                                    src={
                                      items?.image
                                        ? ImgUrl + "/" + items?.image
                                        : defaultImg
                                    }
                                  ></img>
                                </div>
                                <div className="treatbox-title">
                                  {items?.title}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </section>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </BrowserView>
      <MobileView>
        {loading == true ? (
          <>
            <section className="section">
              <div className="container">
                <div className="section-title section-center">
                  <h2>
                    <Skeleton width={200} height={30} />
                  </h2>
                  <span className="shape-line">
                    {" "}
                    {/* Fix: Use className instead of class */}
                    <Skeleton width={100} height={20} />
                  </span>
                  <p>
                    <Skeleton width={340} height={30} />
                  </p>
                </div>
                <div className="row g-3">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Autoplay, Navigation, Pagination]}
                    navigation
                    autoplay={{ delay: 3500 }}
                  >
                    <SwiperSlide>
                      <div className="col-lg-3">
                        <div className="treatbox">
                          <div className="treatbox-img">
                            <Skeleton width={"100%"} height={220} />
                          </div>
                          <div className="treatbox-title">
                            <Skeleton width={"100%"} height={30} />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {Treatmentdata?.length > 0 ? (
              <section className="section-gap-md treatment">
                <div className="container">
                  <div className="section-title section-center">
                    <h2>Our Treatments</h2>
                    <span className="shape-line">
                      {" "}
                      {/* Fix: Use className instead of class */}
                      <i className="icon-19"></i>
                    </span>
                    <p>
                    Get personlized and high quality care for your heart beacause your best deserve the best.
                    </p>
                  </div>
                  <div className="row g-3">
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      pagination={{ clickable: true }}
                    >
                      {Treatmentdata.map((items, index) => (
                        <SwiperSlide key={index}>
                          <div className="col-lg-3">
                            <div
                              className="treatbox"
                              onClick={() => {
                                navigate(`/treatments/${items?.slug}`);
                              }}
                            >
                              <div className="treatbox-img">
                                <img
                                  src={
                                    items?.image
                                      ? ImgUrl + "/" + items?.image
                                      : defaultImg
                                  }
                                  alt={items?.title}
                                ></img>
                              </div>
                              <div className="treatbox-title">
                                {items?.title}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </section>
            ) : null}
          </>
        )}
      </MobileView>
    </>
  );
};

export default TreatmentLayout;
