import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ApiService } from "../../services/apiServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import constants from "../../services/constants";

function MenuModal({ showMenuCart, handleChildMenuCartData }) {
  const { show, setShow } = showMenuCart;
  const [menuData, setMenuData] = useState([]);
  const [settingData, setSettingData] = useState({});
  const [settingImagePath, setSettingImagePath] = useState("");


  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(-1);

  const handleClose = () => {
    setShow(false);
    handleChildMenuCartData(false);
  };

  const handleShow = () => {
    setShow(true);
    handleChildMenuCartData(true);
  };

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getMenuData();
      getSettingsData();
    }
    didMountRef.current = false;
  }, []);

  const getMenuData = () => {
    ApiService.Commanfetchrequest("menu-list").then((res) => {
      if (res.status === "success") {
        setMenuData(res.data);
      }
    });
  };

  const getSettingsData = () => {
    ApiService.Commanfetchrequest("settingdata").then((res) => {
      if ((res.status = "success")) {
        setSettingData(res.data);
        setSettingImagePath(res.setting_image_path);
      }
    });
  };

  const toggleSubMenu = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <>
      {menuData?.length > 0 ? (
        <>
          <div className="p-2">
            <a href="/" className="mlogo">
              <img
               src={
                settingData.logo
                  ? settingImagePath + settingData.logo
                  : constants.DEFAULT_IMAGE
              }
                width={125}
                height={39}
              ></img>
            </a>
          </div>
          <hr style={{ marginTop: "0" }}></hr>
          <div className="sidemenu">
            <ul>
              {menuData.map((parent, index) => {
                const hasChildren =
                  parent.children && parent.children.length > 0;
                const isSubMenuOpen = openSubMenuIndex === index;

                return (
                  <li key={index} className="sub-menu">
                    <div className="sub-menu-inner">
                      <a href={parent.url}>{parent.menu_name}</a>

                      {hasChildren && (
                        <div
                          className="right"
                          onClick={() => toggleSubMenu(index)}
                        >
                          <FontAwesomeIcon
                            icon={isSubMenuOpen ? faCaretUp : faCaretDown}
                          />
                        </div>
                      )}
                    </div>

                    {hasChildren && isSubMenuOpen && (
                      <ul>
                        {parent.children.map((childItem, childIndex) => (
                          <li key={childIndex} className="sub-menu">
                            <div className="sub-menu-inner">
                              <a href={childItem.url}>{childItem.menu_name}</a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default MenuModal;
