import React, { useState, useEffect, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../services/apiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import Book_AppointmentModal from "../Modals/book_appointment";

function Home_Bookappointment() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);

  const handleClose = () => {
    setBookAppointmentModal(false);
  };
  const [appointmentDetails, setAppointmentDetails] = useState({
    appointment_fname: "",
    appointment_lname: "",
    appointment_date: "",
    appointment_time: "",
    appointment_mobile: "",
    appointment_email: "",
    appointment_message: "",
  });

  const resetForm = () => {
    setAppointmentDetails({
      appointment_fname: "",
      appointment_lname: "",
      appointment_date: "",
      appointment_time: "",
      appointment_mobile: "",
      appointment_email: "",
      appointment_message: "",
    });
  };
  const bookAppointment = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      function emailRegex(appointment_email) {
        return /\S+@\S+\.\S+/.test(appointment_email);
      }
      function mobileRegex(appointment_mobile) {
        return /^(\+\d{1,3}[- ]?)?\d{10}$/.test(appointment_mobile);
      }

      if (!mobileRegex(appointmentDetails.appointment_mobile)) {
        toast.error("Please Enter 10 digit mobile number");
        return false;
      }
      if (!emailRegex(appointmentDetails.appointment_email)) {
        toast.error("Invalid Email! Please provide a valid email address.");
        return false;
      }

      ApiService.Commanpostrequest(
        "booking-appointment",
        appointmentDetails
      ).then((res) => {
        if (res.status == "success") {
          setSuccessMessage(res.message);
          resetForm();
          setTimeout(() => {
            setSuccessMessage();
          }, 3000);
        } else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setErrorMessage();
          }, 2000);
        }
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppointmentDetails({
      ...appointmentDetails,
      [name]: value,
    });

    const element = e.target;
    if (value !== "") {
      element.style.border = "";
    }
  };

  return (
    <>
      <BrowserView>
        <ToastContainer />
        <section className="cta-banner-area text-center">
          <div className="container-fluid">
            <div className="edu-cta-banner">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="section-title text-center">
                    <span className="pre-title">
                      "Your Heart Deserve The Best"
                    </span>
                    <h2>We’re Here For You ! Book An Appointment-</h2>

                    <p>We provide best heart care, when you need it, Get Personlized and High Quality Care for Your Heart beacause your best deserve the best.
                    </p>
                  </div>
                  <a className="edu-btn btn-large"
                    href="javascript:void(0)"
                    onClick={() => setBookAppointmentModal(true)}>Book Appointment <i class="icon-4"></i></a>
                </div>
              </div>
              <ul className="shape-group">
                <li className="shape-01 scene">
                  <img src="/images/shape-10.png" alt="shape" />
                </li>
              </ul>
            </div>
          </div>
        </section>


      </BrowserView>
      <MobileView>
        <section className="cta-banner-area text-center">
          <div className="container-fluid">
            <div className="edu-cta-banner">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="section-title text-center">
                    <span className="pre-title">
                      "Your Heart Deserve The Best"
                    </span>
                    <h2>We’re Here For You ! Book An Appointment-</h2>

<p>We provide best heart care, when you need it, Get Personlized and High Quality Care for Your Heart beacause your best deserve the best.
</p>
                  </div>
                  <a className="edu-btn btn-large"
                    href="javascript:void(0)"
                    onClick={() => setBookAppointmentModal(true)}>Book Appointment <i class="icon-4"></i></a>
                </div>
              </div>
              <ul className="shape-group">
                <li className="shape-01 scene">
                  <img src="/images/shape-10.png" alt="shape" />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </MobileView>
      <Book_AppointmentModal
        show={bookAppointmentModal}
        handleClose={handleClose}
      />
    </>
  );
}

export default Home_Bookappointment;
