import { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { ApiService } from "../services/apiServices";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Elements/Modals/menu_modal";
import constants from "../services/constants";
import { useLocation } from "react-router-dom";
import Book_AppointmentModal from "../Elements/Modals/book_appointment";
function Header() {
  const [headermenu, setheadermenu] = useState([]);
  const [Imgpath, setImgpath] = useState("");
  const [settingData, setSettingData] = useState({});
  const [settingImagePath, setSettingImagePath] = useState("");
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);

  const didMountRef = useRef(true);

  const handleClose = () => {
    setBookAppointmentModal(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      getMenuData();
      getSettingsData();
      
    }
    didMountRef.current = false;
  });
  const getMenuData = () => {
    ApiService.Commanfetchrequest("menu-list").then((response) => {
      if (response.status == "success") {
        setheadermenu(response?.data);
        // setImgpath(response?.slider_image_path)
      }
    });
  };
  const handleShowMenuCart = () => setShowMenuCart(true);
  const [showMenuCart, setShowMenuCart] = useState(false);

  const handleChildMenuCartData = (status) => {
    setShowMenuCart(status);
  };
  const handleMenuClose = () => {
    setShowMenuCart(false);
  };

  const getSettingsData = () => {
    ApiService.Commanfetchrequest("settingdata").then((res) => {
      if ((res.status = "success")) {
        setSettingData(res.data);
        setSettingImagePath(res.setting_image_path);
      }
    });
  };
  const location = useLocation();

  return (
    <>
      <BrowserView>
        <div className="upperheader">
          <marquee>{settingData.header_top}</marquee>
        </div>
        <div className="topheader">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="header-info">
                  <ul>
                    <li>
                      <a href={"tel:" + settingData?.admin_support_mobile}>
                        <i className="icon-phone"></i>{" "}
                        {settingData?.admin_support_mobile}
                      </a>
                    </li>
                    <li>
                      <a href={"mailto:" + settingData?.admin_support_email}>
                        <i className="icon-envelope"></i>
                        {settingData?.admin_support_email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="header-social">
                  <ul>
                    {settingData?.facebook_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.facebook_url} target="new">
                            <i class="icon-facebook"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {settingData?.twitter_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.twitter_url} target="new">
                            <i class="icon-twitter"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {settingData?.instagram_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.instagram_url} target="new">
                            <i class="icon-instagram"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {settingData?.linkedin_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.linkedin_url} target="new">
                            <i class="icon-linkedin2"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {settingData?.youtube_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.youtube_url} target="new">
                            <i class="icon-youtube"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="header">
          <div className="container-fluid">
            <div className="header-left">
              <a className="logo" href="/">
                <img
                  src={
                    settingData.logo
                      ? settingImagePath + settingData.logo
                      : constants.DEFAULT_IMAGE
                  }
                  width={300}
                  height={93}
                  alt="Dr. Prashant Dwivedi"
                />
              </a>
            </div>
            {headermenu?.length > 0 ? (
              <>
                <div className="header-center">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <ul className="navbar-nav">
                      {headermenu?.map((items, index) => {
                        return (
                          <>
                            {!items?.children?.length > 0 ? (
                              <>
                                <li className="nav-item" key={index}>
                                  <a
                                    className={location.pathname === items?.url ? "nav-link active" : "nav-link"}
                                    href={items?.url}
                                  >
                                    {items?.menu_name}
                                  </a>
                                </li>
                              </>
                            ) : (
                              <>
                                <li className="nav-item dropdown dropdown-hover">
                                  <a
                                    id={`navbarDropdown${index}`}
                                    // className="nav-link dropdown-toggle"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className={
                                      location.pathname.includes(items?.url)
                                        ? "nav-link dropdown-toggle active"
                                        : "nav-link dropdown-toggle"
                                    }
                                  >
                                    {items?.menu_name}
                                  </a>
                                  {items?.children.length > 0 ? (
                                    <>
                                      <ul
                                        className="dropdown-menu submenu"
                                        aria-labelledby={`navbarDropdown${index}`}
                                      >
                                        {items?.children?.map(
                                          (childitem, indexchild) => {
                                            return (
                                              <>
                                                <li key={indexchild}>
                                                  <a
                                                    className={
                                                      location.pathname ===
                                                      childitem?.url
                                                        ? "dropdown-item active"
                                                        : "dropdown-item"
                                                    }
                                                    href={childitem?.url}
                                                  >
                                                    {childitem?.menu_name}
                                                  </a>
                                                  {childitem?.children.length >
                                                  0 ? (
                                                    <>
                                                      <ul
                                                        className="dropdown-menu submenu"
                                                        aria-labelledby={`navbarDropdown${indexchild}`}
                                                      >
                                                        {childitem?.children?.map(
                                                          (
                                                            subchild,
                                                            indexsubchild
                                                          ) => {
                                                            return (
                                                              <>
                                                                <li
                                                                  key={
                                                                    indexsubchild
                                                                  }
                                                                >
                                                                  <a
                                                                    className={
                                                                      location.pathname ===
                                                                      subchild?.url
                                                                        ? "dropdown-item active"
                                                                        : "dropdown-item"
                                                                    }
                                                                    href={
                                                                      subchild?.url
                                                                    }
                                                                  >
                                                                    {
                                                                      subchild?.menu_name
                                                                    }
                                                                  </a>
                                                                </li>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </li>
                                              </>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="header-right justify-content-end">
              <a
                href="#"
                className="edu-btn btn-medium"
                onClick={() => setBookAppointmentModal(true)}
              >
                Book Appointment <i class="icon-4"></i>
              </a>
            </div>
          </div>
        </header>
      </BrowserView>

      <MobileView>
        <div className="upperheader">
          <marquee>{settingData.header_top}</marquee>
        </div>
        <div className="topheader">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="header-info">
                  <ul>
                    <li>
                      <a href={"tel:" + settingData?.admin_support_mobile}>
                        <i className="icon-phone"></i>{" "}
                        {settingData?.admin_support_mobile}
                      </a>
                    </li>
                    <li className="d-none">
                      <a href={"mailto:" + settingData?.admin_support_email}>
                        <i className="icon-envelope"></i>
                        {settingData?.admin_support_email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6">
                <div className="header-social">
                  <ul>
                    {settingData?.facebook_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.facebook_url} target="new">
                            <i class="icon-facebook"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {settingData?.twitter_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.twitter_url} target="new">
                            <i class="icon-twitter"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {settingData?.instagram_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.instagram_url} target="new">
                            <i class="icon-instagram"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {settingData?.linkedin_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.linkedin_url} target="new">
                            <i class="icon-linkedin2"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {settingData?.youtube_url != null ? (
                      <>
                        <li>
                          <a href={settingData?.youtube_url} target="new">
                            <i class="icon-youtube"></i>
                          </a>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="mheader">
          <div className="mheader-left">
            <a
              href="javascript:void(0)"
              className="sidenav-trigger"
              onClick={handleShowMenuCart}
            >
              <img src="/images/menu-bar.png" className="wd-24" />
            </a>
            <a href="/" className="mlogo">
              <img
               src={
                settingData.logo
                  ? settingImagePath + settingData.logo
                  : constants.DEFAULT_IMAGE
              }
                width={125}
                height={39}
              ></img>
            </a>
          </div>
          <div
            className="mheader-right"
            onClick={() => setBookAppointmentModal(true)}
          >
            <a href="#" className="edu-btn btn-small mt-2 bookbutton">
              Book Appointment
            </a>
          </div>
        </header>
      </MobileView>
      <Modal
        show={showMenuCart}
        onHide={() => handleChildMenuCartData(false)}
        className="left menu-modal"
      >
        {showMenuCart && (
          <MenuModal
            showMenuCart={{ show: showMenuCart, setShow: setShowMenuCart }}
            handleChildMenuCartData={handleChildMenuCartData}
          />
        )}
      </Modal>
      <Book_AppointmentModal
        show={bookAppointmentModal}
        handleClose={handleClose}
      />
    </>
  );
}

export default Header;
