import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ApiService } from "../../components/services/apiServices";
import YouTube from "react-youtube";
import Skeleton from "react-loading-skeleton";
import constants from "../../components/services/constants";
import { Helmet } from "react-helmet";

function Video() {
  const didMountRef = useRef(true);
  const [testimonialData, setTestimonialData] = useState([]);
  const [pageContent, setpageContent] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (didMountRef.current) {
      getPagesData();
      videoTestinomials();
    }
    didMountRef.current = false;
  }, []);

  const getPagesData = () => {
    const pageData = {
      slug: "about/video",
    };

    setLoading(true);
    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setpageContent(res.data);
        setHeaderImageUrl(res.header_image_path);
        setLoading(false);
      }
    });
  };
  const videoTestinomials = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("video-testimonials").then((response) => {
     
      if (response.status == "success") {
        setTestimonialData(response.testimonialdata);
         setLoading(false);
      }
    });
  };
  const opts = {
    height: "300px",
    width: "100%",
  };
  return (
    <>
            <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ?<meta property="og:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ?<meta name="twitter:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>

        
      </Helmet>
      <Header />

      {loading == true ? (
        <>
          <div className="subheader">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1>
                    <Skeleton width={"23%"} height={30} />
                  </h1>
                  <nav aria-label="breadcrumb">
                    <Skeleton width={"20%"} height={20} />
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <section className="section-gap-md">
            <div className="container">
              <div className="innertestimonialbox">
                <div className="row">
                  <div className="col-lg-6">
                    <h5 className="review-author mb-1">
                      <Skeleton width={"100%"} height={50}/>
                    </h5>
                    <p className="mb-2"><Skeleton width={20} height={18}/></p>
                    <div
                      className="innertestimonialboxtext">
                        <Skeleton width={"100%"} height={18}/>
                      </div>
                  </div>
                  <div className="col-lg-6">
                   <Skeleton width={"100%"} height={260}/>
                  </div>

                  <div className="col-lg-6">
                  <Skeleton width={"100%"} height={260}/>
                  </div>
                  <div className="col-lg-6">
                    <h5 className="review-author mb-1">
                    <Skeleton width={"100%"} height={50}/>
                    </h5>
                    <p className="mb-2"><Skeleton width={30} height={30}/></p>
                    <div
                      className="innertestimonialboxtext">
                        <Skeleton width={"100%"} height={15}/>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div
            className="subheader"
            style={{
              backgroundImage: `url(${
                headerImageUrl + pageContent.page_header_image
              })`,
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1>{pageContent.page_name}</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {pageContent.page_name}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <section className="section-gap-md">
            <div className="container">
              {testimonialData?.map((value, index) => {
                return (
                  <div className="innertestimonialbox" key={index}>
                    <div className="row">
                      {index % 2 === 0 ? (
                        <>
                          <div className="col-lg-6">
                            <h5 className="review-author mb-1">
                              {value.testimonial_name}
                            </h5>
                            <p className="mb-2">{value.testimonial_post}</p>
                            <div
                              className="innertestimonialboxtext"
                              dangerouslySetInnerHTML={{
                                __html: value.testimonial_desc,
                              }}
                            ></div>
                          </div>
                          <div className="col-lg-6">
                            <YouTube
                              videoId={value.testimonial_video}
                              opts={opts}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-6">
                            <YouTube
                              videoId={value.testimonial_video}
                              opts={opts}
                            />
                          </div>
                          <div className="col-lg-6">
                            <h5 className="review-author mb-1">
                              {value.testimonial_name}
                            </h5>
                            <p className="mb-2">{value.testimonial_post}</p>
                            <div
                              className="innertestimonialboxtext"
                              dangerouslySetInnerHTML={{
                                __html: value.testimonial_desc,
                              }}
                            ></div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
}

export default Video;
