import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../components/services/apiServices";
import { Swiper, SwiperSlide } from "swiper/react";
import constants from "../../components/services/constants";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
function Blogs() {
  const { type } = useParams();
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const defaultImg = constants.DEFAULT_IMAGE;
  const [blogData, setBlogData] = useState([]);
  const [categoryData, setCategoryData] = useState("");
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [defaultImage, setDefaultImage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [latestCategoryData, setLatestCategoryData] = useState([]);
  const [latestBlogImageUrl, setLatestBlogImageUrl] = useState("");
  const [blogListDefaultImage, setBlogListDefaultImage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (didMountRef.current) {
      getPagesData();
      if (type == "category") {
        categorywisebloglist(slug);
      } else {
        newsBlogList();
      }
      latestBlogs();
    }
    didMountRef.current = false;
  }, []);

  const getPagesData = () => {
    const pageData = {
      slug: "blogs",
    };

    setLoading(true);
    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setPageContent(res.data);
        setHeaderImageUrl(res.header_image_path);
        setLoading(false);
      }
    });
  };
  const newsBlogList = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("newsandblogslist").then((res) => {
      if (res?.status === "success") {
        setBlogData(res?.blogsData);
        setBlogImageUrl(res?.blog_url);
        setDefaultImage(res?.default_image_baseurl);
        setLoading(false);
      }
    });
  };

  const latestBlogs = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("blog-list-sidebar-data").then((res) => {
      if (res?.status === "success") {
        setLatestCategoryData(res.blogCategories);
        setLatestBlogData(res.latestblogdata);
        setLatestBlogImageUrl(res.blog_url);
        setBlogListDefaultImage(res.default_image_baseurl);
        setLoading(false);
      }
    });
  };

  const categorywisebloglist = (category_slug) => {
    const dataString = {
      category_slug: category_slug,
    };

    setLoading(true);
    ApiService.Commanpostrequest("category-wise-blogs-list", dataString).then(
      (res) => {
        if (res?.status === "success") {
          setBlogData(res.blogsData);
          setBlogImageUrl(res.blog_url);
          setDefaultImage(res.default_image_baseurl);
          setLoading(false);
        }
      }
    );
  };

  return (
    <>
    <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ?<meta property="og:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ?<meta name="twitter:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
            <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        
      </Helmet>
      <Header />

      {loading == true ? (
        <>

          <div className="subheader">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1>
                    <Skeleton width={"15%"} height={25} />
                  </h1>
                  <nav aria-label="breadcrumb"></nav>
                </div>
              </div>
            </div>
          </div>
          <section className="section-gap-md">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="bloglistbox">
                    <div className="bloglistbox-thumb">
                      <a>
                        <Skeleton width={"100%"} height={450} />
                      </a>
                    </div>
                    <div className="bloglistbox-content">
                      <div className="listmeta mb-3">
                        <ul>
                          <li className="large">
                            <Skeleton width={"10%"} height={12} />
                          </li>
                          <li className="large">
                            <Skeleton width={"10%"} height={16} />
                          </li>
                        </ul>
                      </div>
                      <h2 className="title">
                        <Skeleton width={"100%"} height={40} />
                      </h2>
                      <p>
                        <Skeleton width={"100%"} height={100} />
                      </p>
                      <Skeleton width={"15%"} height={40} />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="sidebarbox">
                    <h2 className="title">
                      <Skeleton width={"50%"} height={30} />
                    </h2>
                    <div className="ltblog">
                      <div className="ltblog-img">
                        <Skeleton width={"100%"} height={80} />
                      </div>
                      <div className="ltblog-content">
                        <div className="listmeta">
                          <ul>
                            <li>
                              <Skeleton width={20} height={20} />
                            </li>
                          </ul>
                        </div>
                        <h2 className="title">
                          <a>
                            <Skeleton width={30} height={30} />
                          </a>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="sidebarbox">
                    <h2 className="title">
                      <Skeleton width={"50%"} height={30} />
                    </h2>
                    <div className="sidelist">
                      <ul>
                        <li>
                          <a>
                            <Skeleton width={40} height={40} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Skeleton End */}
        </>
      ) : (
        <>
          <div
            className="subheader"
            style={{
              backgroundImage: `url(${
                headerImageUrl + pageContent.page_header_image
              })`,
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1>{pageContent.page_name}</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {pageContent.page_name}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="section-gap-md">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  {blogData.length > 0 &&
                    blogData.map((value, index) => (
                      <div className="bloglistbox" key={index}>
                        <div className="bloglistbox-thumb">
                          <a href={`/blogs/${value?.blog_slug}`}>
                            <img
                              src={
                                value?.blog_image !== null
                                  ? blogImageUrl + value?.blog_image
                                  : defaultImg
                              }
                            ></img>
                          </a>
                        </div>
                        <div className="bloglistbox-content">
                          <div className="listmeta mb-3">
                            <ul>
                              <li className="large">
                                <i class="icon-27"></i>
                                {moment(value.created_at).format("DD-MM-YYYY")}
                              </li>
                              <li className="large">
                                <i class="icon-25"></i> by admin
                              </li>
                            </ul>
                          </div>
                          <h2 className="title">
                            <a href={`/blogs/${value.blog_slug}`}>
                              {value?.blog_name}
                            </a>
                          </h2>
                          <p
                            className="bloglistbox-short"
                            dangerouslySetInnerHTML={{
                              __html: value.blog_short_description,
                            }}
                          ></p>
                          <a
                            href={`/blogs/${value?.blog_slug}`}
                            class="edu-btn btn-small"
                          >
                            Read More <i class="icon-4"></i>
                          </a>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="col-lg-4">
                  <div className="sidebarbox">
                    <h2 className="title">Latest Blog</h2>
                    {latestBlogData.map((value) => (
                      <div className="ltblog">
                        <div className="ltblog-img">
                          <img
                            src={
                              value.blog_image != null
                                ? latestBlogImageUrl + value.blog_image
                                : constants.DEFAULT_IMAGE
                            }
                            alt="Blog Images"
                          ></img>
                        </div>
                        <div className="ltblog-content">
                          <div className="listmeta">
                            <ul>
                              <li>
                                <i class="icon-27"></i>
                                {moment(value.created_at).format("DD-MM-YYYY")}
                              </li>
                            </ul>
                          </div>
                          <h2 className="title">
                            <a href={`/blogs/${value.blog_slug}`}>
                              {value.blog_name}
                            </a>
                          </h2>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="sidebarbox">
                    <h2 className="title">Categories</h2>
                    <div className="sidelist">
                      <ul>
                        {latestCategoryData.map((value) => (
                          <li
                            onClick={(e) =>
                              categorywisebloglist(value.category_slug)
                            }
                          >
                            <a href={`/blogs/category/` + value.category_slug}>
                              {value.category_name}{" "}
                              {value.blog_count != 0 ? (
                                <span>({value.blog_count})</span>
                              ) : (
                                ""
                              )}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <Footer />
    </>
  );
}

export default Blogs;
