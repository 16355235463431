import React, { useEffect, useRef, useState, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { ApiService } from "../../services/apiServices";
import Skeleton from "react-loading-skeleton";
import { Autoplay} from "swiper/modules";



const CrossingBoundries = () => {
  const didMountRef = useRef(true);
  const [boundariesData, setBoundariesData] = useState([]);
  const [image_path, setImage_path] = useState("");
  const [loading, setLoading] = useState();


  useEffect(() => {
    if (didMountRef.current) {
      getBoundariesData();
    }
    didMountRef.current = false;
  });

  const getBoundariesData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("crossing-boundaries").then((response) => {
      if (response.status == "success") {
        setBoundariesData(response.boundariesData);
        setImage_path(response.image_path);
        setLoading(false);
      }
    });
  };

  const sliderRef = useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <>
      <BrowserView>
        {loading == true ? (
          <>
            <section
              className="section-gap-md crossboundry"
              style={{ background: "#f6f7f8" }}
            >
              <div className="container-fluid ">
                <div className="row g-0 align-items-center">
                  <div className="col-lg-4">
                    <Skeleton width={"100%"} height={500} />
                  </div>
                  <div className="col-lg-8">
                    <div>
                      <Swiper
                        spaceBetween={15}
                        slidesPerView={3.5} 
                        ref={sliderRef}
                      >
                        {[...Array(4)].map((_, index) => (
                          <SwiperSlide>
                            <div className="crosingbox">
                              <>
                                <div className="crosingboximg" key={index}>
                                  <Skeleton width={"100%"} height={400} />
                                  {/* <img src='https://drprashantdwivedi.com/assets/img/web/Varanasi-Infinity-Hospital.jpg'></img> */}
                                </div>
                                <div className="crosingboxcontent">
                                  <h6>
                                    <Skeleton width={"100%"} height={50} />
                                  </h6>
                                  <p>
                                    <Skeleton width={"100%"} height={20} />
                                  </p>
                                </div>
                              </>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div className="swiper-buttons__box">
                        <div
                          className="swiper-button swiper-button-prev"
                          style={{ alignItems: "center" }}
                          onClick={handlePrev}
                        >
                      
                          <Skeleton width={40} height={10}/>
                        </div>
                        <div
                          className="swiper-button swiper-button-next"
                          onClick={handleNext}
                          style={{ alignItems: "center" }}
                        >
                         
                           <Skeleton width={40} height={10}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {boundariesData?.length > 0 ? (
              <>
                <section
                  className="section-gap-md crossboundry"
                  style={{ background: "#f6f7f8" }}
                >
                  <div className="container-fluid px-0">
                    <div className="row g-0 align-items-center">
                      <div className="col-lg-4">
                        <div className="crossboundry-about">
                          <div className="section-title tx-white">
                            <h2 className="tx-white">
                              Crossing The Boundaries{" "}
                            </h2>
                            <span className="shape-line tx-white">
                              <i className="icon-19"></i>
                            </span>
                            <p>
                            We Teach, Train and Treat Across the world.
                            </p>
                          </div>
                          <a class="edu-btn btn-border btn-medium" href="#">
                            View More <i class="icon-4"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="crossbslider">
                          <Swiper
                            spaceBetween={15}
                            slidesPerView={3.5}
                            ref={sliderRef}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                          >
                            {boundariesData.map((value, index) => (
                              <SwiperSlide>
                                <div className="crosingbox">
                                  <div className="crosingboximg">
                                    <img
                                      src={
                                        value?.cs_crossing_image
                                          ? image_path +
                                            value?.cs_crossing_image
                                          : ""
                                      }
                                    ></img>
                                    {/* <img src='https://drprashantdwivedi.com/assets/img/web/Varanasi-Infinity-Hospital.jpg'></img> */}
                                  </div>
                                  <div className="crosingboxcontent">
                                    <h6>{value.cs_crossing_name}</h6>
                                    <p>{value.cs_crossing_short_desc}</p>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          <div className="swiper-buttons__box">
                            <div className="swiper-button swiper-button-prev" onClick={handlePrev}>
                              <span>
                              <svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 14L26 14" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path><path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path></svg>
                              </span>
                            </div>
                            <div
                              className="swiper-button swiper-button-next"
                              onClick={handleNext}
                            >
                              <span>
                              <svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 14L26 14" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path><path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" stroke-width="3" stroke-linecap="square"></path></svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </BrowserView>

      <MobileView>
        {loading == true ? (
          <>
            <section
              className="section-gap-md crossboundry"
              style={{ background: "#f6f7f8" }}
            >
              <div className="container-fluid">
                <div className="row g-0 align-items-center">
                  <div className="col-lg-4">
                    <Skeleton width={"100%"} height={500} />
                  </div>
                  <div className="col-lg-8">
                    <div>
                      <Swiper
                        spaceBetween={15}
                        slidesPerView={1}
                        ref={sliderRef}
                        
                      >
                        <SwiperSlide>
                          <div className="crosingbox">
                            <div className="crosingboximg">
                              <Skeleton width={"100%"} height={300} />
                              {/* <img src='https://drprashantdwivedi.com/assets/img/web/Varanasi-Infinity-Hospital.jpg'></img> */}
                            </div>
                            <div className="crosingboxcontent">
                              <h6>
                                <Skeleton width={"100%"} height={50} />
                              </h6>
                              <p>
                                <Skeleton width={"100%"} height={20} />
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <div className="swiper-buttons__box">
                        <div
                          className="swiper-button swiper-button-prev"
                          style={{ alignItems: "center" }}
                          onClick={handlePrev}
                        >
                          <Skeleton width={30} height={10}/>
                        </div>
                        <div
                          className="swiper-button swiper-button-next"
                          onClick={handleNext}
                          style={{ alignItems: "center" }}
                        >
                         <Skeleton width={30} height={10}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {boundariesData?.length > 0 ? (
              <>
                <section
                  className="section-gap-md crossboundry"
                  style={{ background: "#f6f7f8" }}
                >
                  <div className="container-fluid">
                    <div className="row g-0 align-items-center">
                      <div className="col-lg-4">
                        <div className="crossboundry-about">
                          <div className="section-title tx-white">
                            <h2 className="tx-white">
                              Crossing The Boundaries{" "}
                            </h2>
                            <span className="shape-line tx-white">
                              <i className="icon-19"></i>
                            </span>
                            <p>
                            We Tech, Train and Treat Across the world.
                            </p>
                          </div>
                          <a class="edu-btn btn-border btn-medium" href="#">
                            View More <i class="icon-4"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="crossbslider">
                          <Swiper
                            spaceBetween={15}
                            slidesPerView={1}
                            ref={sliderRef}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                          >
                            {boundariesData.map((value, index) => (
                              <SwiperSlide>
                                <div className="crosingbox">
                                  <div className="crosingboximg">
                                    <img
                                      src={
                                        value?.cs_crossing_image
                                          ? image_path +
                                            value?.cs_crossing_image
                                          : ""
                                      }
                                    ></img>
                                    {/* <img src='https://drprashantdwivedi.com/assets/img/web/Varanasi-Infinity-Hospital.jpg'></img> */}
                                  </div>
                                  <div className="crosingboxcontent">
                                    <h6>{value.cs_crossing_name}</h6>
                                    <p>{value.cs_crossing_short_desc}</p>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          <div className="swiper-buttons__box">
                            <div
                              className="swiper-button swiper-button-prev"
                              style={{ alignItems: "center" }}
                              onClick={handlePrev}
                            >
                              <span>
                                <svg
                                  width="27"
                                  height="22"
                                  viewBox="0 0 32 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 14L26 14"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                    strokeLinecap="square"
                                  ></path>
                                  <path
                                    d="M17.6514 3L29.6514 14L17.6514 25"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                    strokeLinecap="square"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <div
                              className="swiper-button swiper-button-next"
                              onClick={handleNext}
                              style={{ alignItems: "center" }}
                            >
                              <span>
                                <svg
                                  width="27"
                                  height="22"
                                  viewBox="0 0 32 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2 14L26 14"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                    strokeLinecap="square"
                                  ></path>
                                  <path
                                    d="M17.6514 3L29.6514 14L17.6514 25"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                    strokeLinecap="square"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </MobileView>
    </>
  );
};

export default CrossingBoundries;
