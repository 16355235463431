import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import YouTube, { YouTubeProps } from 'react-youtube';
import { ApiService } from '../../services/apiServices';

function HomeVideo() {

    const didMountRef = useRef(true);
    const [videoData,setVideoData] = useState({})
    useEffect(() => {
  
      if(didMountRef.current){
        getVideoData()
      }
      didMountRef.current = false;
     
     }, [])
    const getVideoData = () => {

        ApiService.Commanfetchrequest("featured-video").then((res) => {
          if (res.status == "success") {
            setVideoData(res.videoData);
          }
        });
      };
    // const videoIds = ['oCDZCpWbw3M' ];
    const opts = {
        height: '590',
        width: '940',
        playerVars: {
          autoplay: 1,
        },
      };

      const optsmobile = {
        height: '300',
        width: '380',
        playerVars: {
          autoplay: 1,
        },
      };

      const onReady = (event) => {
        event.target.playVideo();
      };
  return (

        <>
        <BrowserView>
        <section className="section-gap-md">
                  <div className="container">
                    <div className='text-center'>
                    <YouTube videoId={videoData.video_url} onReady={onReady} opts={opts} />
                    </div>
                  </div>
                  </section>
        </BrowserView>

        <MobileView>
        <section className="section-gap-md">
                  <div className="container">
                    <div className='text-center'>
                    <YouTube videoId={videoData.video_url} onReady={onReady} opts={optsmobile} />
                    </div>
                  </div>
                  </section>
        </MobileView>
       


        </>


  )
}

export default HomeVideo