import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../components/services/apiServices";
import YouTube from "react-youtube";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import constants from "../../components/services/constants";

function Videos() {
  const didMountRef = useRef(true);
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState();
  const [pageContent, setpageContent] = useState({});
  const[headerImageUrl,setHeaderImageUrl] = useState('')
  useEffect(() => {
    if (didMountRef.current) {
      getPagesData();
      getVideoData()
    }
    didMountRef.current = false;
  }, []);
  const getPagesData = () => {
    const pageData = {
      slug: "gallery/videos",
    };

    ApiService.Commanpostrequest("pages-content", pageData).then((res) => {
      if (res.status == "success") {
        setpageContent(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
    });
  };
  const getVideoData = () => {
    setLoading(true);
    ApiService.Commanfetchrequest("videos").then((res) => {
      if (res.status === "success") {
        setVideoData(res.videoData);

        setTimeout(()=>{
            setLoading(false);
        },1000)
        
      }
    });
  };

  const opts = {
    height: "400",
    width: "100%",
  };
  return (
    <>
        <Helmet>
        <title>{pageContent.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageContent.page_meta_desc != null ? pageContent.page_meta_desc :"Dr Prashant Dwivedi"} />
        {pageContent.page_meta_keyword != null ?<meta name="keywords" content={pageContent.page_meta_keyword} />:"Dr Prashant Dwivedi"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageContent.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image"  content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>
        <meta property="og:url" content={window.location.href} />
        {pageContent.page_meta_desc != null ?<meta property="og:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta name="twitter:title" content={pageContent.page_meta_title} />
        {pageContent.page_meta_desc != null ?<meta name="twitter:description" content={pageContent.page_meta_desc} />:"Dr Prashant Dwivedi"}
        <meta property="twitter:image" content={pageContent.page_header_image ? headerImageUrl + pageContent.page_header_image: constants.META_DEFAULT_IMAGE}/>

        
      </Helmet>
      <Header />

      <div className="subheader" style={{backgroundImage: `url(${headerImageUrl + pageContent.page_header_image})`}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
              <h1>{pageContent.page_name}</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                    {pageContent.page_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

      {loading == true ? (
        <>
          <section className="mt-5">
            <div className="container-fluid">
              <div className="row">
                {[...Array(6)].map((_, index) => (
                  <>
                    <div className="col-lg-6 mb-4" key={index}>
                      <div>
                        <Skeleton width={"100%"} height={300} />
                      </div>
                      <h4>
                        <Skeleton width={"100%"} height={40} />
                      </h4>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {videoData?.length > 0 ? (
            <>
              <section className="mt-5">
                <div className="container-fluid">
                  <div className="row">
                    {videoData.map((value, index) => (
                      <div key={index} className="col-lg-6 mb-4">
                        <div>
                          <YouTube
                            videoId={value.gallery_video_url}
                            opts={opts}
                          />
                        </div>
                        <h4 style={{color:"#C1272D",fontSize:"16px",paddingTop:"30px",lineHeight:'2'}}>{value.gallery_title}</h4>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
            
            </>
          )}
        </>
      )}

      <Footer />
    </>
  );
}

export default Videos;
